'use strict';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { DialogTitle } from '@radix-ui/react-dialog';
import React from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Dialog, DialogClose, DialogContent, DialogTrigger } from './Dialog';

export const ImageDialog = ({ children, imageUrl }: { children: React.ReactNode; imageUrl: string }) => (
  <Dialog>
    <DialogTrigger className="cursor-zoom-in" asChild>
      {children}
    </DialogTrigger>
    <DialogContent>
      <DialogTitle className="hidden">Image</DialogTitle>
      <DialogClose className="absolute -right-2.5 -top-2.5 rounded-full bg-white p-2 shadow-2xl">
        <XMarkIcon className="h-10 text-black" />
      </DialogClose>
      <img src={imageUrl} className="2xl:max-h-9/10 2xl:max-w-screen-xl" alt="zoomed in image" />
    </DialogContent>
  </Dialog>
);

'use strict';
import { CogIcon } from '@heroicons/react/20/solid';
import { DialogTitle } from '@radix-ui/react-dialog';
import cls from 'classnames';
import React, { useState } from 'react';
import { Dialog, DialogContent } from './Dialog';
import { SelectField } from './FormFields/SelectField';
import { PrimaryButton } from './PrimaryButton';

export const ExportPage = ({ creditorCodes: initialCreditorCodes }: { creditorCodes: { value: string | number; label: string }[] }) => {
  const [loading, setLoading] = useState(false);
  const [option, setOption] = useState<string | null>(null);
  const [openSuccessModal, setOpenSuccessModal] = useState<boolean>(false);
  const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
  const [creditorCodes, setCreditorCodes] = useState(initialCreditorCodes);

  if (!creditorCodes || !creditorCodes.length) {
    setCreditorCodes([{ value: 'all', label: 'All' }]);
  }

  const exportButtonPressed = (e: any) => {
    e.preventDefault();

    if (option) {
      setLoading(true);
      fetch(`/dealers/api/downloadProductSpreadsheet?CreditorCode=${option}`)
        .then(() => {
          setOpenSuccessModal(true);
        })
        .catch((error) => {
          console.error('Error:', error);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <form className="my-4 flex flex-col gap-y-10">
      <SelectField
        invertColor={true}
        title="Creditor Codes"
        value={option}
        onChange={(value: React.ChangeEvent<HTMLSelectElement>) => {
          setOption(value.target.value);
        }}
        options={creditorCodes}
        required={true}
      />
      <PrimaryButton
        onClick={exportButtonPressed}
        className={cls('flex flex-grow-0 justify-center gap-4', loading && 'pointer-events-none opacity-50 hover:opacity-50')}
      >
        {loading && <CogIcon className="h-6 w-6 animate-spin" />}
        <span>Export Products</span>
      </PrimaryButton>
      <Dialog open={openSuccessModal}>
        <DialogContent>
          <DialogTitle className="hidden">Dealer Export</DialogTitle>
          <div className="flex flex-col gap-6">
            <h2 className="text-3xl font-normal">Dealer Export emailed</h2>
            <p>Your export will be completed shortly, keep an eye on your emails in the meantime</p>
            <div className="flex justify-end gap-4">
              <button onClick={() => setOpenSuccessModal(false)} className="rounded-lg bg-green px-4 py-2">
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog open={openErrorModal}>
        <DialogContent>
          <DialogTitle className="hidden">Dealer Export Failed</DialogTitle>
          <div className="flex flex-col gap-6">
            <h2 className="text-3xl font-normal">Dealer Export Failed</h2>
            <p>Export file failed to generate. Please try submit again. If the problem persists, please contact your Administrator</p>
            <div className="flex justify-end gap-4">
              <button onClick={() => setOpenErrorModal(false)} className="rounded-lg bg-green px-4 py-2">
                OK
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </form>
  );
};

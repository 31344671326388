import { mdiCheck, mdiLoading, mdiPackageVariantClosedMinus, mdiTrashCanOutline } from '@mdi/js';
import { Icon } from '@mdi/react';
import Tippy from '@tippyjs/react';
import cls from 'classnames';
import { HTMLAttributes, InputHTMLAttributes, PropsWithChildren, useEffect, useState } from 'react';
import { useDebounce, useTimeout } from 'usehooks-ts';
import type { CartItem, Product, ProductsPagination, ProductsPaginationPage, StockIssue } from '../../services/dealerCart';
import { getCart, getProducts, saveCart, submitCart } from '../../services/dealerCart';
import { getPriceCodes, GetPriceTypesResponse } from '../../services/dealerPrices';
import { ImageDialog } from '../ImageDialog';

const moneyFormatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'NZD', currencyDisplay: 'narrowSymbol' });
const formatMoney = (value: number) => moneyFormatter.format(value);

type FeedbackMessage = {
  message: string;
  type: 'good' | 'bad';
};

const productsPerPage = 20;

export const DealerOrderForm = () => {
  const [cartItems, setCartItemsValue] = useState<CartItem[]>([]);
  const [orderReference, setOrderReference] = useState<string>('');
  const [quantity, setQuantity] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const [stockIssues, setStockIssues] = useState<StockIssue[]>([]);

  const [priceType, setPriceType] = useState<string>();

  useEffect(() => {
    getPriceCodes().request.then((value: GetPriceTypesResponse) => {
      setPriceType(value.currentPriceType);
    });
  }, []);

  const [notes, setNotes] = useState<string>('');

  const [submitting, setSubmitting] = useState<boolean | 'save' | 'stock' | 'submit'>(false);
  const [feedbackMessage, setFeedbackMessage] = useState<FeedbackMessage | null>(null);
  useTimeout(() => setFeedbackMessage(null), feedbackMessage ? 10000 : null);

  const [search, setSearch] = useState<string>('');
  const debouncedSearch = useDebounce(search, 500);
  const [page, setPage] = useState<number>(0);
  const [products, setProducts] = useState<Product[]>([]);
  const [pagination, setPagination] = useState<ProductsPagination | null>(null);

  const [productsLoading, setProductsLoading] = useState<boolean>(true);

  const setCartItems = (items: CartItem[]) => {
    const sortedItems = items.sort((a, b) => a.code.localeCompare(b.code));

    setCartItemsValue(sortedItems);
    setQuantity(items.reduce((acc, { quantity }) => acc + quantity, 0));
    setTotal(items.reduce((acc, { price, quantity }) => acc + price * quantity, 0));
  };

  const updateCartItem = (product: Product | CartItem, quantity: number) => {
    // Remove from Cart
    if (quantity === 0) {
      setCartItems(cartItems.filter((item) => item.code !== product.code));
      return;
    }

    // Update in Cart
    const index = cartItems.findIndex((item) => item.code === product.code);
    if (index !== -1) {
      const newCartItems = [...cartItems];
      newCartItems[index] = {
        ...newCartItems[index],
        quantity: quantity,
      };
      setCartItems(newCartItems);
      return;
    }

    // Add to Cart
    const isCartItem = 'quantity' in product;
    setCartItems([
      ...cartItems,
      {
        code: product.code,
        title: isCartItem ? product.title : product.name,
        price: isCartItem ? product.price : product.dealerPrice,
        quantity: quantity,
      },
    ]);
  };

  const saveOrder = async () => {
    try {
      setSubmitting('save');
      const cart = await saveCart({ items: cartItems, reference: orderReference, notes }).request;
      setOrderReference(cart.reference ?? '');
      setNotes(cart.notes ?? '');
      setCartItems(cart.items);

      setFeedbackMessage({ message: 'Order saved', type: 'good' });
    } catch (e) {
      console.error(e);
      setFeedbackMessage({ message: 'Order failed to save', type: 'bad' });
    } finally {
      setSubmitting(false);
    }
  };

  const submitOrder = async () => {
    // save
    try {
      setSubmitting('save');
      await saveCart({ items: cartItems, reference: orderReference, notes }).request;
      setFeedbackMessage({ message: 'Order saved', type: 'good' });
    } catch (e) {
      console.error(e);
      setFeedbackMessage({ message: 'Order failed to save', type: 'bad' });
      setSubmitting(false);
    }

    // check stock
    // try {
    //   setSubmitting('stock');
    //   const {issues} = await checkStock().request;
    //   setStockIssues(issues);
    //
    //   if (issues.length > 0) {
    //     setFeedbackMessage(null);
    //     setSubmitting(false);
    //     return;
    //   } else {
    //     setFeedbackMessage({message: 'All items are in stock', type: 'good'});
    //   }
    // } catch (e) {
    //   console.error(e);
    //   setFeedbackMessage({message: 'Stock check failed to process', type: 'bad'});
    //   setSubmitting(false);
    // }

    // submit order
    try {
      setSubmitting('submit');
      const [{ redirect }] = await Promise.all([
        submitCart().request,
        new Promise((r) => setTimeout(r, 2500)), // artificial submit delay to allow submitting message to display
      ]);

      setFeedbackMessage({ message: 'Order submitted for processing', type: 'good' });
      window.location.href = redirect;
    } catch (e) {
      console.error(e);
      setFeedbackMessage({ message: 'Stock check failed to process', type: 'bad' });
      setSubmitting(false);
    }
  };

  const fixStockIssues = (issue: StockIssue | StockIssue[], action: 'remove' | 'reduce') => {
    const issueCodes = Array.isArray(issue) ? issue.map((i) => i.code) : [issue.code];
    let newCartItems;

    if (action === 'remove') {
      newCartItems = cartItems.filter((item) => !issueCodes.includes(item.code));
    } else {
      const issues = Array.isArray(issue) ? issue : [issue];
      newCartItems = [...cartItems];
      for (const issue of issues) {
        const cartItemIndex = cartItems.findIndex((item) => item.code === issue.code);
        newCartItems[cartItemIndex] = {
          ...newCartItems[cartItemIndex],
          quantity: issue.available,
        };
      }
    }

    setCartItems(newCartItems);
    setStockIssues(stockIssues.filter((i) => !issueCodes.includes(i.code)));
  };

  useEffect(() => {
    setProductsLoading(true);
    const { request, abortController } = getProducts(page, productsPerPage, debouncedSearch);

    request.then(({ data, pagination }) => {
      if (!abortController?.signal.aborted) {
        setProducts(data);
        setPagination(pagination);
        setProductsLoading(false);
      }
    });
    return () => abortController?.abort();
  }, [page, debouncedSearch]);

  useEffect(() => {
    const { request, abortController } = getCart();

    request.then(({ items, notes, reference }) => {
      if (!abortController.signal.aborted) {
        setOrderReference(reference ?? '');
        setNotes(notes ?? '');
        setCartItems(items);
      }
    });

    return () => abortController.abort();
  }, []);

  return (
    <div className="mx-10 flex items-start gap-8">
      <Card className="grow">
        <CardPadding className="flex items-center justify-between">
          <Heading>Products</Heading>
          <Input placeholder="Search" value={search} onChange={(e) => setSearch((e.target as HTMLInputElement).value)} />
        </CardPadding>
        <CardBody className="mt-4" loading={productsLoading}>
          <Table>
            <Thead>
              <tr>
                <th>Product</th>
                <th className={cls('text-right', priceType === 'Hidden' && 'hidden')}>Price</th>
                <th>Estimate Stock</th>
                <th className="w-28 text-right">Cart</th>
              </tr>
            </Thead>
            <Tbody divide>
              {products?.map((product) => {
                const quantityInCart = cartItems.find((item) => item.code === product.code)?.quantity ?? 0;

                return (
                  <TbodyTr key={product.code}>
                    <td>
                      <div className="flex items-center gap-2">
                        <div className="aspect-square w-10 bg-gray-100">
                          {product.thumbnailImage ? (
                            <ImageDialog imageUrl={product.thumbnailImage}>
                              <img alt="Product Image" className="h-full w-full object-contain" src={product.thumbnailImage} />
                            </ImageDialog>
                          ) : null}
                        </div>
                        <div>
                          <div className="text-xs font-light text-slate-450">{product.code}</div>
                          <div>{product.name}</div>
                        </div>
                      </div>
                    </td>
                    <td className={cls('w-20 text-right', priceType === 'Hidden' && 'hidden')}>{formatMoney(product.dealerPrice)}</td>
                    <td className="w-48">
                      <StockLabel status={product.stockLabel} />
                    </td>
                    <td>
                      <div className="ml-auto flex w-28 items-center justify-end gap-2">
                        <Input
                          placeholder={'1'}
                          type="number"
                          value={quantityInCart || ''}
                          min={0}
                          step={1}
                          onChange={(e) => updateCartItem(product, parseInt(e.target.value || '0'))}
                          className="spinners-none w-12 pr-0.5 text-center"
                        />
                        <div className="w-7">
                          {quantityInCart ? (
                            <Icon path={mdiCheck} className="h-4" />
                          ) : (
                            <button className="font-light text-green-700" type="button" onClick={() => updateCartItem(product, 1)}>
                              Add
                            </button>
                          )}
                        </div>
                      </div>
                    </td>
                  </TbodyTr>
                );
              })}
            </Tbody>
          </Table>

          <div className="mb-2">
            {pagination ? (
              <div className="mt-4 flex items-center justify-center gap-5">
                {page > 0 ? (
                  <button type="button" onClick={() => setPage((p) => p - 1)}>
                    ←
                  </button>
                ) : (
                  <span className="text-slate-450">←</span>
                )}
                {pagination.paginationPages?.map((p, index) => (
                  <PaginationButton key={p.pageNumber ?? `more-${index}`} page={p} setPage={setPage} current={page + 1} />
                ))}
                {pagination.notLastPage ? (
                  <button type="button" onClick={() => setPage((p) => p + 1)}>
                    →
                  </button>
                ) : (
                  <span className="text-slate-450">→</span>
                )}
              </div>
            ) : null}
          </div>
        </CardBody>
      </Card>
      <Card className="flex w-[25.5rem] flex-col gap-6">
        <CardPadding>
          <Heading>Cart</Heading>
        </CardPadding>

        <Table>
          <Thead>
            <tr>
              <th className="leading-none">
                <span className="border-b-2 border-dotted" title="Hint: Hover over a product row to view the product name!">
                  Product
                </span>
              </th>
              <th className="text-right">Quantity</th>
              <th className={cls('text-right', priceType === 'Hidden' && 'hidden')}>Total</th>
              <th></th>
            </tr>
          </Thead>
          <Tbody>
            {cartItems.map((item) => (
              <TbodyTr key={item.code} title={item.title}>
                <td>
                  <span className="border-b-2 border-dotted">{item.code}</span>
                </td>
                <td>
                  <Input
                    small
                    placeholder={'0'}
                    type="number"
                    value={item.quantity}
                    min={1}
                    step={1}
                    onChange={(e) => updateCartItem(item, parseInt(e.target.value || '1'))}
                    className={cls('spinners-none w-12 text-center', priceType === 'Hidden' && 'ml-auto block')}
                  />
                </td>
                <td className={cls('text-right leading-none', priceType === 'Hidden' && 'ml-auto hidden')}>
                  {formatMoney(item.price * item.quantity)}
                </td>
                <td className="leading-none">
                  <button className="text-red-500" type="button" onClick={() => updateCartItem(item, 0)}>
                    <Icon path={mdiTrashCanOutline} className="h-5" />
                  </button>
                </td>
              </TbodyTr>
            ))}
          </Tbody>
        </Table>

        <CardPadding className="flex justify-between border-t pt-2 text-sm">
          <span>
            {quantity} items from {cartItems.length} products
          </span>
          <span className={cls(priceType === 'Hidden' && 'ml-auto hidden')}>{formatMoney(total)}</span>
        </CardPadding>

        <CardPadding className="flex flex-col gap-6">
          <div>
            <Input
              placeholder="Order Reference Number"
              className="w-full"
              value={orderReference}
              required
              onChange={(e) => setOrderReference(e.target.value ?? '')}
            />
            {orderReference ? null : <p className="mt-0.5 text-sm text-red-500">Order Reference Number is required</p>}
          </div>
          <Input maxLength={30} placeholder="Notes/Comments" value={notes} onChange={(e) => setNotes(e.target.value)} />
        </CardPadding>

        {stockIssues.length ? (
          <div className={cls('mt-4 border bg-red-100 text-sm')}>
            <div className="px-6 py-3">
              <p>
                {stockIssues.length === 1 ? '1 item in your order is unavailable.' : `${stockIssues.length} items in your order are unavailable.`}
                <br />
                Please review the items below and select an action.
              </p>
              <p className="mt-2">
                <span className="font-light">Need more?</span> If you wish to order more than the stock available, please email us.
              </p>
            </div>
            <div className="bg-white py-3">
              <div className="mb-2 flex flex-col gap-2 border-b px-6 pb-3">
                <div className="flex items-center justify-between gap-1 leading-none">
                  <p className="flex items-center gap-1 leading-none">
                    <Icon path={mdiPackageVariantClosedMinus} className="h-4 text-yellow-700" /> Change order to maximum available
                  </p>
                  <Tippy content="Change all items below with stock available to the maximum available">
                    <button
                      className="text-xs font-light"
                      type="button"
                      onClick={() => {
                        const lowStockIssues = stockIssues.filter((issue) => issue.issue === 'low');
                        fixStockIssues(lowStockIssues, 'reduce');
                      }}
                    >
                      Fix All
                    </button>
                  </Tippy>
                </div>
                <div className="flex items-center justify-between gap-1 leading-none">
                  <p className="flex items-center gap-1 leading-none">
                    <Icon path={mdiTrashCanOutline} className="h-4 text-red-500" /> Remove from order
                  </p>
                  <Tippy content="Remove all items below from the order">
                    <button
                      className="text-xs font-light"
                      type="button"
                      onClick={() => {
                        const outStockIssues = stockIssues.filter((issue) => issue.issue === 'out');
                        fixStockIssues(outStockIssues, 'remove');
                      }}
                    >
                      Fix All
                    </button>
                  </Tippy>
                </div>
              </div>
              <Table>
                <Thead>
                  <tr>
                    <th className="w-2/5 leading-none">
                      <span className="border-b-2 border-dotted" title="Hint: Hover over a product row to view the product name!">
                        Product
                      </span>
                    </th>
                    <th className="w-2/5">Quantity</th>
                    <th className="w-1/5"></th>
                  </tr>
                </Thead>
                <Tbody>
                  {stockIssues.map((issue) => {
                    const cartItem = cartItems.find((item) => item.code === issue.code)!;

                    return (
                      <TbodyTr key={issue.code} title={cartItem.title}>
                        <td>
                          <span className="border-b-2 border-dotted">{issue.code}</span>
                        </td>
                        <td>
                          {issue.available} of {cartItem.quantity} available
                        </td>
                        <td>
                          <div className="flex justify-between gap-4">
                            {issue.issue === 'low' ? (
                              <Tippy content={`Change order to maximum available (${issue.available})`}>
                                <button type="button" onClick={() => fixStockIssues(issue, 'reduce')}>
                                  <Icon path={mdiPackageVariantClosedMinus} className="h-5 text-yellow-700" />
                                </button>
                              </Tippy>
                            ) : (
                              <span />
                            )}
                            <Tippy content="Remove from order">
                              <button type="button" onClick={() => fixStockIssues(issue, 'remove')}>
                                <Icon path={mdiTrashCanOutline} className="h-5 text-red-500" />
                              </button>
                            </Tippy>
                          </div>
                        </td>
                      </TbodyTr>
                    );
                  })}
                </Tbody>
              </Table>
            </div>
          </div>
        ) : feedbackMessage ? (
          <div className={cls('mt-4 border px-6 py-3 text-sm', feedbackMessage.type === 'good' ? 'bg-green-100' : 'bg-red-100')}>
            {feedbackMessage.message}
          </div>
        ) : (
          <div className="mt-4 border bg-slate-50 px-6 py-3 text-sm">
            You may save this order at any time. When this order is submitted we will check available stock levels and may ask you to remove items
            before processing your order.
          </div>
        )}

        {submitting ? (
          <CardPadding>
            <div className="text flex items-center gap-2 rounded-md border bg-blue-50 px-6 py-3">
              <Icon path={mdiLoading} className="h-6 animate-spin" />
              {submitting === 'save' && 'Saving order...'}
              {submitting === 'stock' && 'Checking stock levels...'}
              {submitting === 'submit' && 'Submitting order for processing...'}
            </div>
          </CardPadding>
        ) : (
          <CardPadding className="flex justify-between gap-8">
            <button type="button" className="dealer btn-secondary" onClick={saveOrder}>
              Save Order
            </button>
            <button
              type="button"
              className="dealer btn-primary"
              onClick={submitOrder}
              disabled={cartItems.length === 0 || orderReference.trim() == ''}
            >
              Submit Order
            </button>
          </CardPadding>
        )}
      </Card>
    </div>
  );
};

const Card = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => <div className={cls('rounded-md bg-white py-6', className)} {...props} />;

const CardPadding = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => <div className={cls('px-6', className)} {...props} />;

const CardBody = ({
  className,
  loading = false,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  loading?: boolean;
}) => (
  <div
    className={cls('relative', loading && 'before:absolute before:inset-0 before:z-10 before:animate-pulse before:bg-white/80', className)}
    {...props}
  />
);

const Heading = ({ className, ...props }: HTMLAttributes<HTMLHeadingElement>) => <h2 className={cls('text-xl font-normal', className)} {...props} />;

const inputClasses = 'px-1 border-b border-gray-600 hover:border-black  focus:outline-none focus:border-black ';
const Input = ({
  className,
  small,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  small?: boolean;
}) => <input className={cls(inputClasses, small ? 'py-0.5' : 'py-2', className)} {...props} />;

const StockLabel = ({ status: [label, status] }: { status: Product['stockLabel'] }) => {
  const sharedClasses = 'block w-28 text-center font-light items-center rounded-md px-2 py-1 text-xs';
  if (status === 'warning') {
    return <span className={cls('bg-yellow-100 text-yellow-800', sharedClasses)}>{label}</span>;
  } else if (status === 'error') {
    return <span className={cls('bg-red-100 text-red-700', sharedClasses)}>{label}</span>;
  } else if (status === 'success') {
    return <span className={cls('bg-green-100 text-green-700', sharedClasses)}>{label}</span>;
  } else {
    return <span className={cls('bg-blue-100 text-blue-700', sharedClasses)}>{label}</span>;
  }
};

const PaginationButton = ({
  page: { pageNumber },
  current,
  setPage,
}: {
  page: ProductsPaginationPage;
  setPage: (page: number) => void;
  current: number;
}) => {
  if (!pageNumber) {
    return <span>...</span>;
  }

  if (pageNumber === current) {
    return <span className="font-light text-slate-450">{pageNumber}</span>;
  }

  return (
    <button key={pageNumber} type="button" onClick={() => setPage(pageNumber! - 1)}>
      {pageNumber}
    </button>
  );
};

const Thead = (props: PropsWithChildren) => (
  <thead
    className="mb-6 text-left text-xs font-light uppercase leading-none tracking-widest text-slate-450 [&_th:first-of-type]:pl-6 [&_th:last-of-type]:pr-6 [&_th]:px-4 [&_th]:py-2 [&_th]:font-light"
    {...props}
  />
);

const Tbody = ({
  divide,
  className,
  ...props
}: PropsWithChildren<{
  divide?: boolean;
  className?: string;
}>) => (
  <tbody
    className={cls(
      'text-sm [&_td:first-of-type]:pl-6 [&_td:last-of-type]:px-6 [&_td]:px-4 [&_td]:py-2',
      divide && 'divide-y divide-gray-200',
      className,
    )}
    {...props}
  />
);

const Table = ({
  className,
  fixed = false,
  ...props
}: PropsWithChildren<{
  className?: string;
  fixed?: boolean;
}>) => <table className={cls('w-full', fixed ? 'table-fixed' : 'table-auto', className)} {...props} />;

const TbodyTr = (props: HTMLAttributes<HTMLTableRowElement>) => <tr className="focus-within:bg-slate-50 hover:bg-slate-50" {...props} />;

import { CogIcon } from '@heroicons/react/20/solid';
import { mdiClose, mdiEmailOutline, mdiFacebook, mdiWeb } from '@mdi/js';
import { Icon } from '@mdi/react';
import axios from 'axios';
import cls from 'classnames';
import { Formik, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { FindAStore } from './FindAStore';
import { getMakes, getModels, getSeries, Make, Model, Series } from './FitMy4x4';
import { SelectField } from './FormikFields/SelectField';
import { TextAreaField } from './FormikFields/TextAreaField';
import { TextField } from './FormikFields/TextField';
import { PresenceBlock } from './PresenceBlock';
import { classes, PrimaryButton } from './PrimaryButton';

const Wrapper = ({ children, className }: { className?: string }) => <div className={cls('bg-black px-6 py-5', className)}>{children}</div>;

const ChosenDealer = ({
  dealer,
  changeDealer,
}: {
  dealer: { name?: string; description?: string; address?: string; website?: string; email?: string; facebook?: string };
  changeDealer: () => void;
}) => (
  <div className="mt-8 flex flex-col gap-2 bg-black p-4">
    <div className="flex justify-between">
      <div className="text-white text-xl">{dealer.name ?? 'Select a Dealer below'}</div>

      {dealer.name ? (
        <button type="button" className="text-green hover:opacity-80" onClick={changeDealer}>
          Change
        </button>
      ) : (
        <a className="text-green hover:opacity-80" href={window.location.pathname + '#select-a-dealer'}>
          Select
        </a>
      )}
    </div>
    <div className="text-sm text-gray-400">{dealer.description}</div>
    <div className="text-white text-sm">{dealer.address}</div>
    <div className="text-white flex flex-row gap-4 text-sm">
      {dealer.website ? (
        <a href={dealer.website} target="_blank" className="flex items-center gap-2">
          <Icon path={mdiWeb} className="h-5 text-green" />
          Website
        </a>
      ) : null}
      {dealer.email ? (
        <a href={'mailto:' + dealer.email} target="_blank" className="flex items-center gap-2">
          <Icon path={mdiEmailOutline} className="h-5 text-green" />
          Email
        </a>
      ) : null}
      {dealer.facebook ? (
        <a href={dealer.facebook} target="_blank" className="flex items-center gap-2">
          <Icon path={mdiFacebook} className="h-5 text-green" />
          Facebook
        </a>
      ) : null}
    </div>
  </div>
);

const VehicleSelect = ({ formikProps, vehicle }: { vehicle: string }) => {
  const [allMakes, setAllMakes] = useState<Make[] | []>([]);
  const [allModels, setAllModels] = useState<Model[] | []>([]);
  const [allSeries, setAllSeries] = useState<Series[] | []>([]);
  const [openVehiclePanel, setOpenVehiclePanel] = useState(false);
  const { values: contactDetails } = useFormikContext();

  useEffect(() => {
    getMakes(setAllMakes);
  }, []);
  return (
    <>
      <Wrapper>
        <div className="text-sm text-gray-500">Select for vehicle</div>
        <div className="flex justify-between">
          {vehicle ? <h3 className="text-white text-xl">{vehicle}</h3> : <p className="text-white px-6">There is currently no vehicle selected</p>}
          <button className="text-xl text-green" type="button" onClick={() => setOpenVehiclePanel((current) => !current)}>
            Select
          </button>
        </div>
      </Wrapper>

      <PresenceBlock visible={openVehiclePanel}>
        <Wrapper>
          <Formik initialValues={formikProps.initialValues} onSubmit={formikProps.onSubmit}>
            {({ handleSubmit, values }) => {
              useEffect(() => {
                getModels(values.make, setAllModels);
              }, [values.make]);

              useEffect(() => {
                getSeries(values.make, values.model, setAllSeries);
              }, [values.make, values.model]);

              return (
                <form className="my-2 lg:mb-10 lg:mt-6 lg:space-y-6" onSubmit={handleSubmit}>
                  <SelectField
                    title="Make"
                    name="make"
                    options={allMakes.map((make) => ({
                      value: make.ID,
                      label: make.Name,
                    }))}
                    invertColor={false}
                  />
                  <SelectField
                    title="Model"
                    name="model"
                    options={allModels.map((model) => ({
                      value: model.ID,
                      label: model.Name,
                    }))}
                    invertColor={false}
                  />
                  <SelectField
                    title="Series"
                    name="series"
                    options={allSeries.map((series) => ({
                      value: series.ID,
                      label: series.Name,
                    }))}
                    invertColor={false}
                  />

                  <div className="flex space-x-4">
                    <a
                      href="fit-my-4x4?clear=1"
                      className="relative z-20 w-6 bg-green px-8 py-4 font-extrabold uppercase text-black hover:cursor-pointer hover:opacity-80"
                    >
                      <svg width="18" height="18" className="-ml-2 mt-1 fill-black" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.1666 2.47825L15.5216 0.833252L8.99992 7.35492L2.47825 0.833252L0.833252 2.47825L7.35492 8.99992L0.833252 15.5216L2.47825 17.1666L8.99992 10.6449L15.5216 17.1666L17.1666 15.5216L10.6449 8.99992L17.1666 2.47825Z" />
                      </svg>
                    </a>
                    <a
                      className={cls(classes, 'text-center')}
                      href={
                        '/online-quote/changeVehicle?' +
                        'first-name=' +
                        encodeURIComponent(contactDetails.fName) +
                        '&last-name=' +
                        encodeURIComponent(contactDetails.sName) +
                        '&phone=' +
                        encodeURIComponent(contactDetails.phone) +
                        '&email=' +
                        encodeURIComponent(contactDetails.email) +
                        '&post-code=' +
                        encodeURIComponent(contactDetails.postCode) +
                        '&region=' +
                        encodeURIComponent(contactDetails.region) +
                        '&country=' +
                        encodeURIComponent(contactDetails.country) +
                        '&dealer=' +
                        encodeURIComponent(contactDetails.dealer) +
                        '&quoteType=' +
                        encodeURIComponent(contactDetails.quoteType) +
                        '&comment=' +
                        encodeURIComponent(contactDetails.comment) +
                        (values.make
                          ? '&make=' + values.make + (values.model ? '&model=' + values.model + (values.series ? '&series=' + values.series : '') : '')
                          : '') +
                        '&redirect-back=1'
                      }
                      type="submit"
                    >
                      Change Vehicle
                    </a>
                  </div>
                </form>
              );
            }}
          </Formik>
        </Wrapper>
      </PresenceBlock>
    </>
  );
};

const Cart = ({ setCart, cart, tableContent }) => {
  const { isSubmitting } = useFormikContext();

  const changeQuantity = (value: string, index: number) => {
    const itemIndex = cart.findIndex((item, key) => key === index);

    const updatedItem = {
      ...cart[itemIndex],
      Quantity: parseInt(value),
      TotalPrice: cart[itemIndex]['PricePer'] * parseInt(value),
    };

    const updatedCart = [...cart];
    updatedCart[itemIndex] = updatedItem;

    setCart(updatedCart);
  };

  const removeCartItem = (index: number) => {
    const updatedCart = [...cart];
    updatedCart.splice(index, 1);
    setCart(updatedCart);
  };

  return (
    <Wrapper>
      <table className="w-full">
        <tr>
          <th className="text-left text-sm font-normal text-gray-500">Quote items</th>
          <th className="text-left text-sm font-normal text-gray-500">Quantity</th>
        </tr>

        {cart.length > 0 ? (
          cart.map((cartItem, index) => (
            <tr key={cartItem.Code} className="border-b border-gray-600">
              <td className="padding flex space-x-6 py-5">
                <div className="h-20 w-20 bg-cover bg-center bg-no-repeat" style={{ backgroundImage: 'url(' + cartItem.PrimaryImage + ')' }}></div>
                <div className="flex-col space-y-2">
                  <h3 className="text-white text-xl">{cartItem.Title}</h3>
                </div>
              </td>
              <td className="w-24">
                <div className="relative">
                  <TextField
                    title="qty"
                    type="number"
                    autoComplete="off"
                    name={`cart[${index}].Quantity`}
                    className="spinners-none"
                    defaultValue={cartItem.Quantity}
                    min={1}
                    dealerTextField={true}
                    onChange={(e) => changeQuantity(e.target.value, index)}
                    invertColor={false}
                  />
                  <button
                    type="button"
                    className="text-white absolute right-0 top-4 bg-transparent px-1 py-1 hover:text-red-450"
                    onClick={(e) => {
                      e.preventDefault();
                      removeCartItem(index);
                    }}
                  >
                    <Icon path={mdiClose} className="text current h-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td className="text-white px-6">There are currently no items in your quote</td>
            <td></td>
            <td></td>
          </tr>
        )}
      </table>
      <p className="text-white mt-4 text-left text-sm font-normal">{tableContent}</p>
      <div className="mt-4 pt-4">
        <PrimaryButton disabled={cart.length === 0 || isSubmitting}>
          <div className="flex justify-center space-x-2">
            {isSubmitting && <CogIcon className="h-6 w-6 animate-spin" style={{ animationDuration: '3s' }} />}
            <span>{isSubmitting ? 'Submitting' : 'Submit'} Quote</span>
          </div>
        </PrimaryButton>
      </div>
    </Wrapper>
  );
};

export const OnlineQuote = ({ apiKey, initialVehicle, defaultValues, underTableContent, tableContent, quoteTypes }) => {
  const [cart, setCart] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [dealer, setSelectDealer] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    axios.get('/online-quote/getCart').then(function (response) {
      setCart(response.data);
    });
    setFirstRender(false);
  }, []);

  const validationSchema = Yup.object().shape({
    fName: Yup.string().required('First name is required'),
    sName: Yup.string().required('Last name is required'),
    quoteType: Yup.string().required('Please select a quote type'),
    phone: Yup.string().required('Phone is required'),
    email: Yup.string().email('Must be valid email').required('Email is required'),
    postCode: Yup.string().required('Postcode is required'),
    dealer: Yup.number().required('Dealer is required'),
  });

  const formikProps = {
    initialValues: {
      cart: cart,
      fName: defaultValues.fName,
      sName: defaultValues.sName,
      phone: defaultValues.phone,
      email: defaultValues.email,
      quoteType: defaultValues.quoteType,
      comment: defaultValues.comment,
      postCode: defaultValues.postCode,
      region: defaultValues.region,
      dealer: parseInt(defaultValues.dealerInitial),
      ...JSON.parse(document.querySelector('[data-react="vehicle"]')?.dataset?.props),
    },

    onSubmit: (data: any) => {
      axios
        .post('/online-quote/submitQuote', data)
        .then(() => (window.location.href = '/online-quote?thank-you=1'))
        .catch((error) => console.log(error));
    },
  };

  return (
    <Formik initialValues={formikProps.initialValues} validationSchema={validationSchema} onSubmit={formikProps.onSubmit}>
      {({ handleSubmit, values, isSubmitting, setFieldValue, errors }) => {
        useEffect(() => {
          if (isSubmitting) {
            setHasSubmitted(true);
          }
        }, [isSubmitting]);
        useEffect(() => {
          if (!firstRender) {
            setFieldValue('cart', cart);
            axios.post('/online-quote/setCart', { cart: cart });
          }
        }, [cart]);
        useEffect(() => {
          if (Object.keys(dealer).length > 0) {
            setFieldValue('dealer', dealer.id);
          }
        }, [dealer]);

        return (
          <form onSubmit={handleSubmit}>
            <div className="grid w-full gap-10 pt-20 lg:grid-cols-2">
              <div>
                <h2 className="pb-4 font-extrabold uppercase">Contact Details *</h2>
                <TextField title="First Name" name="fName" invertColor={true} />
                <TextField title="Last Name" name="sName" invertColor={true} />
                <TextField title="Email Address" name="email" invertColor={true} />
                <TextField title="Phone Number" name="phone" invertColor={true} />
                <SelectField
                  invertColor={true}
                  title="Quote Type"
                  name="quoteType"
                  options={quoteTypes.map((type) => ({
                    value: type,
                    label: type,
                  }))}
                />
                <div className="flex space-x-4">
                  <TextField title="Post Code" name="postCode" className="w-full" invertColor={true} />
                  <TextField title="Region" name="region" className="w-full" invertColor={true} />
                </div>
                <TextAreaField title="Additional Information" defaultValue={defaultValues.comment} name="comment" invertColor={true} />
                <div className="pt-10">
                  <span className="font-bold uppercase">Choose a dealer*</span>
                  {errors['dealer'] && hasSubmitted ? <p className="text-red-450">{errors['dealer']}</p> : null}
                  <ChosenDealer
                    dealer={dealer}
                    changeDealer={() => {
                      setFieldValue('dealer', '');
                      setSelectDealer({});
                    }}
                  />
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <VehicleSelect formikProps={formikProps} vehicle={initialVehicle} />
                <Cart cart={cart} setCart={setCart} tableContent={tableContent} underTableContent={underTableContent} />
                <div className="prose pt-6 lg:prose-lg" dangerouslySetInnerHTML={{ __html: underTableContent }} />
              </div>
              <div id="select-a-dealer" className="lg:col-span-2">
                {Object.keys(dealer).length == 0 && (
                  <div className="w-full">
                    <FindAStore
                      apiKey={apiKey}
                      hasStoreList={false}
                      setSelectDealer={setSelectDealer}
                      darkHeader={false}
                      name="dealer"
                      dealerID={values.dealer}
                    />
                  </div>
                )}
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

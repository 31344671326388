import { Formik } from 'formik';
import { CheckboxField } from './FormikFields/CheckboxField';
import { RadioGroupField } from './FormikFields/RadioGroupField';
import { SelectField } from './FormikFields/SelectField';
import { TextAreaField } from './FormikFields/TextAreaField';
import { TextField } from './FormikFields/TextField';
import { PrimaryButton } from './PrimaryButton';

export const ContactUs = () => {
  const formikProps = {
    initialValues: {
      fName: '',
      sName: '',
      email: '',
      phone: '',
      postCode: '',
      region: '',
      country: '',
      dealer: '',
      enqiryType: '',
      vehicleMake: '',
      vehicleModel: '',
      vehicleSeries: '',
      comment: '',
      subscribe: '',
    },
    onSubmit: (data: any) => {
      console.log(data);
    },
  };

  const enquiryTypes = [
    {
      value: 'vehicle-specific',
      label: 'Vehicle Specific',
    },
    {
      value: 'general',
      label: 'General',
    },
  ];

  return (
    <Formik initialValues={formikProps.initialValues} onSubmit={formikProps.onSubmit}>
      {({ handleSubmit, values }) => (
        <form onSubmit={handleSubmit}>
          <div className="pt-20">
            <TextField title="First Name" name="fName" invertColor />
            <TextField title="Last Name" name="sName" invertColor />
            <TextField title="Email Address" name="email" invertColor />
            <TextField title="Phone Number" name="phone" invertColor />
            <TextField title="Post Code" name="postCode" invertColor />
            <TextField title="Region" name="region" invertColor />
            <TextField title="Country" name="country" invertColor />
            <div className="flex flex-col gap-8 pt-8">
              <span className="text-sm font-bold">Enquiry type</span>
              <RadioGroupField name="enqiryType" containerClassName="flex gap-4" options={enquiryTypes} />
            </div>

            {values.enqiryType === 'vehicle-specific' ? (
              <div className="flex flex-col gap-8 pt-8">
                <span className="text-sm font-bold">Vehicle Details</span>
                <div className="flex flex-row gap-4">
                  <SelectField title="Make" name="vehicleMake" options={[]} invertColor />
                  <SelectField title="Model" name="vehicleModel" options={[]} invertColor />
                  <SelectField title="Series" name="vehicleSeries" options={[]} invertColor />
                </div>
              </div>
            ) : null}

            <div className="flex flex-col gap-8 pt-8">
              <TextAreaField title="Comments" name="comment" invertColor />
            </div>

            <div className="flex flex-col gap-8 pt-8">
              <CheckboxField title="Receive news & promotions from Ironman 4x4" name="subscribe" />
              <CheckboxField title="Accept Terms & Conditions" name="accept" />
            </div>

            <div className="pt-8">
              <PrimaryButton text="Submit Enquiry" type="submit" />
            </div>
          </div>
        </form>
      )}
    </Formik>
  );
};

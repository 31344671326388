import { Dialog, Transition } from '@headlessui/react';
import { Cog6ToothIcon } from '@heroicons/react/20/solid';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { ArrowDownTrayIcon, WrenchScrewdriverIcon } from '@heroicons/react/24/solid';
import cls from 'classnames';
import { Fragment, useState } from 'react';
import { CartIcon, CloseIcon, HamburgerIcon } from '../NavBar';

const MoneyIcon = ({ className }: { className: string }) => (
  <svg width="12" className={className} height="22" viewBox="0 0 12 22" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.166748 14.5H2.50008C2.50008 15.76 4.09841 16.8333 6.00008 16.8333C7.90175 16.8333 9.50008 15.76 9.50008 14.5C9.50008 13.2167 8.28675 12.75 5.72008 12.1317C3.24675 11.5133 0.166748 10.7433 0.166748 7.5C0.166748 5.41167 1.88175 3.63833 4.25008 3.04333V0.5H7.75008V3.04333C10.1184 3.63833 11.8334 5.41167 11.8334 7.5H9.50008C9.50008 6.24 7.90175 5.16667 6.00008 5.16667C4.09841 5.16667 2.50008 6.24 2.50008 7.5C2.50008 8.78333 3.71341 9.25 6.28008 9.86833C8.75342 10.4867 11.8334 11.2567 11.8334 14.5C11.8334 16.5883 10.1184 18.3617 7.75008 18.9567V21.5H4.25008V18.9567C1.88175 18.3617 0.166748 16.5883 0.166748 14.5Z" />
  </svg>
);

const DashboardIcon = ({ className }: { className: string }) => (
  <svg width="22" className={cls(className)} height="22" viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.1667 0.5V7.5H21.5V0.5M12.1667 21.5H21.5V9.83333H12.1667M0.5 21.5H9.83333V14.5H0.5M0.5 12.1667H9.83333V0.5H0.5V12.1667Z" />
  </svg>
);

const iconClasses = 'h6 w-6';

const MenuButtons = [
  {
    name: 'Dashboard',
    icon: () => <DashboardIcon className={cls(iconClasses, 'fill-inherit')} />,
    href: '/dealers',
    activeHref: ['/dealers', '/dealers/'],
  },
  {
    name: 'Orders',
    icon: () => <ShoppingCartIcon className={iconClasses} />,
    href: '/dealers/orders',
    activeHref: ['/dealers/orders', '/dealers/editOrder', '/dealers/orders/', '/dealers/editOrder/', '/dealers/viewOrder/', '/dealers/viewOrder'],
  },
  {
    name: 'Online Quotes',
    icon: () => <CartIcon className={cls(iconClasses, 'fill-inherit')} productInCart={false} />,
    href: '/dealers/quotes',
    activeHref: ['/dealers/quotes', '/dealers/quotes/', '/dealers/viewQuote', '/dealers/viewQuote/'],
  },
  // {
  //     name: 'Invoices',
  //     icon: (classes: string) => <MoneyIcon className={cls(iconClasses, classes)}/>,
  //     // href: ['/dealers/invoices'],
  //     // activeHref: ['/dealers/invoices'],
  //     href: '#',
  //     activeHref: ['#'],
  // },
  {
    name: 'Resources',
    icon: () => <WrenchScrewdriverIcon className={iconClasses} />,
    href: '/dealers/resources',
    activeHref: ['/dealers/resources', '/dealers/resources/'],
  },
  {
    name: 'Exports',
    icon: () => <ArrowDownTrayIcon className={iconClasses} />,
    href: '/dealers/exports',
    activeHref: ['/dealers/exports'],
  },
].map((item) => ({
  ...item,
  active: [...item.activeHref].includes(window.location.pathname),
}));

const Desktop = ({ logoutUrl, className }: { logoutUrl: string; className?: string }) => {
  const DividedButtons = [
    {
      name: 'Account Settings',
      icon: () => <Cog6ToothIcon className={iconClasses} />,
      href: '/dealers/myAccount',
    },
    {
      name: 'Logout',
      icon: () => <DashboardIcon className={cls(iconClasses, 'fill-inherit')} />,
      href: logoutUrl,
    },
  ].map((item) => ({
    ...item,
    active: [item.href, item.href + '/'].includes(window.location.pathname),
  }));
  return (
    <div className={cls('inset-y-0 h-full w-dashboard flex-col', className)}>
      <div className="flex min-h-0 flex-1 flex-col bg-black-dealers">
        <div className="flex flex-col overflow-y-auto pb-4 pt-5">
          <div className="flex flex-shrink-0 items-center px-16 pb-14 pt-1">
            <img className="w-auto" src="/assets/frontend/logo_dashboard.png" alt="Your Company" />
          </div>
          <nav className="flex-1 space-y-1">
            <p className="pl-5 text-sm font-light uppercase leading-relaxed tracking-widest text-slate-325">Menu</p>
            {MenuButtons.map((button) => (
              <a
                key={button.href}
                href={button.href}
                className={cls(
                  'group flex w-full items-center space-x-4 py-4 pl-5 text-lg font-medium',
                  button.active
                    ? 'bg-green fill-black-dealers text-black-dealers'
                    : 'fill-slate-325 text-slate-325 transition hover:bg-green hover:fill-black-dealers hover:text-black hover:opacity-60',
                )}
              >
                {button.icon()}
                <span>{button.name}</span>
              </a>
            ))}
          </nav>
        </div>
        <div className="flex flex-col space-y-1 border-t border-slate-700 py-4">
          {DividedButtons.map((button) => (
            <a
              key={button.href}
              href={button.href}
              className={cls(
                'group flex w-full items-center space-x-4 py-4 pl-5 text-lg font-medium',
                button.active
                  ? 'bg-green fill-black-dealers text-black-dealers'
                  : 'fill-slate-325 text-slate-325 transition hover:bg-green hover:fill-black-dealers hover:text-black hover:opacity-60',
              )}
            >
              {button.icon()}
              <span>{button.name}</span>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export const LeftNav = ({ logoutUrl }: { logoutUrl: string }) => {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      <button onClick={() => setMobileNavOpen(true)} type="button" className="relative block h-mobile w-mobile bg-green lg:hidden">
        <HamburgerIcon />
      </button>
      <Transition.Root show={mobileNavOpen} as={Fragment}>
        <Dialog as="div" className="z-50 lg:hidden" onClose={setMobileNavOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="-translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="-translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel>
              <button onClick={() => setMobileNavOpen(false)} type="button" className="fixed right-0 block h-mobile w-mobile bg-green lg:hidden">
                <CloseIcon />
              </button>
              <Desktop className="flex lg:hidden" logoutUrl={logoutUrl} />
            </Dialog.Panel>
          </Transition.Child>
        </Dialog>
      </Transition.Root>
      <Desktop className="hidden lg:flex" logoutUrl={logoutUrl} />
    </>
  );
};

import { mdiClose } from '@mdi/js';
import { Icon } from '@mdi/react';
import cls from 'classnames';
import React from 'react';

interface SelectFieldProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  title: string;
  onChange: any;
  clear?: any;
  value: any;
  options: { value: any; label: string }[];
  invertColor?: boolean;
}

export const SelectField = ({ title, onChange, clear, value, options, invertColor = false, ...props }: SelectFieldProps) => (
  <div className={cls('relative z-10 flex w-full border-b border-gray-600 transition-all', invertColor ? 'hover:border-black' : 'hover:border-white')}>
    <select
      {...props}
      className={cls(
        'w-full border-0 py-4 outline-0',
        invertColor
          ? 'bg-white ' + (value ? 'text-black' : 'text-gray-500 hover:text-black')
          : 'bg-black ' + (value ? 'text-white' : 'text-white hover:text-white'),
      )}
      placeholder={title}
      onChange={onChange}
      value={value}
    >
      <option className="hidden" value="" hidden>
        {title}
      </option>
      {options.map((option) => (
        <option key={option.value} value={option.value} className={'text-black'}>
          {option.label}
        </option>
      ))}
    </select>

    {value && clear ? (
      <button onClick={clear} type="button" className={cls('right-4 top-5', invertColor ? 'text-black' : 'text-gray-500')}>
        <Icon path={mdiClose} className={cls('h-4', invertColor ? 'text-black' : 'text-white')} />
      </button>
    ) : null}
  </div>
);

import { CogIcon } from '@heroicons/react/20/solid';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';
import axios from 'axios';
import cls from 'classnames';
import { useEffect, useState } from 'react';
import { TextField } from './FormFields/TextField';
import { ImageDialog } from './ImageDialog';

interface Pagination {
  moreThanOnePage: boolean;
  notFirstPage: boolean;
  notLastPage: boolean;
  paginationPages: any[];
  prevLink: string;
  nextLink: string;
}

interface Resource {
  mediaURL: string;
  name: string;
  newWindow: boolean;
  productCodes: Record<string, string>;
  thumbnailImage: string;
  image: string;
  bigImage: string;
}

const PaginationButton = ({
  generateTable,
  link,
  pageNumber,
  type,
}: {
  generateTable: (inputValue: string, start: number | string | null) => void;
  link: string;
  pageNumber?: number;
  type?: string;
}) => {
  let baseUrl = 'https://example.com';
  let dummyUrl = new URL(link, baseUrl);
  const start = new URLSearchParams(dummyUrl.search).get('start');
  const search = new URLSearchParams(dummyUrl.search).get('search') ?? '';

  let content;
  switch (type) {
    case 'next':
      content = '\u2192';
      break;
    case 'prev':
      content = '\u2190';
      break;
    default:
      content = pageNumber;
  }

  return <button onClick={() => generateTable(search, start)}>{content}</button>;
};

export const ResourcesTable = () => {
  const [tableResources, setTableResources] = useState([]);
  const [tablePagination, setTablePagination] = useState<Pagination | null>(null);
  const [tableLoad, setTableLoad] = useState(false);

  useEffect(() => {
    generateTable('');
  }, []);

  const generateTable = (inputValue: string, start: number | string | null = 0): void => {
    setTableLoad(true);
    axios.get(`/dealers/fetchDocuments?search=${inputValue}&start=${start}`).then(function (response) {
      setTablePagination(response.data.pagination);
      setTableResources(response.data.data);
      setTableLoad(false);
    });
  };

  if (!tablePagination) {
    //todo spinner or loading screen
    return null;
  }

  return (
    <div className="my-8 flex flex-col rounded-md bg-white p-6">
      <div className="flex w-full items-center justify-between">
        <h2 className="text-xl font-normal">Documents / Resources</h2>
        <div className="mb-4 flex space-x-4">
          <TextField
            title="Search"
            autoFocus
            dealerTextField={true}
            onChange={(e) => {
              generateTable(e.target.value, 0);
            }}
            invertColor
          />
        </div>
      </div>
      <div className="relative">
        <table className="mt-3 w-full">
          <thead>
            <tr>
              <th className="mb-6 text-left text-xs font-light uppercase leading-none tracking-widest text-slate-450">Name</th>
              <th className="mb-6 text-left text-xs font-light uppercase leading-none tracking-widest text-slate-450">Product Codes</th>
              <th></th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {tableResources?.map((resource: Resource) => {
              const productCodes = Object.keys(resource.productCodes);

              return (
                <tr>
                  <td className="flex items-center space-x-4 py-2 font-light">
                    {resource.thumbnailImage && (
                      <ImageDialog imageUrl={resource.image}>
                        <div
                          className="h-10 w-10 rounded bg-white bg-contain bg-center bg-no-repeat"
                          style={{ backgroundImage: 'url(' + resource.thumbnailImage + ')' }}
                        />
                      </ImageDialog>
                    )}
                    <span>{resource.name}</span>
                  </td>
                  <td className="text-slate-450">{productCodes.join(', ')}</td>
                  <td className="text-slate-450">
                    <a
                      href={resource.mediaURL}
                      target={resource.newWindow ? '_blank' : '_self'}
                      download={!resource.newWindow}
                      className={cls('inline-block rounded-lg bg-green px-4 py-2', resource.mediaURL ? 'opacity-100' : 'opacity-40')}
                    >
                      <ArrowDownTrayIcon className="mx-0 h-6 text-black-dealers" />
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {tableLoad && (
          <>
            <div className="absolute top-0 z-10 h-full w-full bg-white opacity-60" />
            <CogIcon
              className="absolute bottom-0 left-0 right-0 top-0 z-30 mx-auto my-auto h-24 w-24 animate-spin"
              style={{ animationDuration: '3s' }}
            />
          </>
        )}
      </div>

      {!!tablePagination?.moreThanOnePage && (
        <div className="mt-10 flex w-full justify-center space-x-6">
          {Boolean(tablePagination.notFirstPage) && <PaginationButton type="prev" link={tablePagination.prevLink} generateTable={generateTable} />}

          {tablePagination.paginationPages.map((paginationPage: any) => (
            <>
              {Boolean(paginationPage.currentBool) ? (
                <span className="underline">{paginationPage.pageNumber}</span>
              ) : Boolean(paginationPage.link) ? (
                <PaginationButton pageNumber={paginationPage.pageNumber} link={paginationPage.link} generateTable={generateTable} />
              ) : (
                <span>...</span>
              )}
            </>
          ))}

          {Boolean(tablePagination.notLastPage) && <PaginationButton type="next" link={tablePagination.nextLink} generateTable={generateTable} />}
        </div>
      )}
    </div>
  );
};

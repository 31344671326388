'use strict';
import { Dialog, Popover, Transition } from '@headlessui/react';
import cls from 'classnames';
import { Formik } from 'formik';
import { Fragment, useEffect, useReducer, useState } from 'react';
import { fitMy4X4FormikProps, getMakes, getModels, getSeries, Make, Model, Series } from './FitMy4x4';
import { SelectField } from './FormikFields/SelectField';
import { TextField } from './FormikFields/TextField';
import { PrimaryButton } from './PrimaryButton';
import { SecondaryButton } from './SecondaryButton';

const navBarWidth = 'mx-auto max-w-10xl px-2 lg:px-8';

const DropDownIcon = ({ open }: { open: boolean }) => (
  <svg className={cls(open && 'rotate-180 transition duration-300')} width="10" height="5" viewBox="0 0 10 5" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0L5 5L10 0H0Z" />
  </svg>
);

export const CartIcon = ({ className, productInCart }: { className?: string; productInCart: boolean }) => (
  <div className="relative">
    <svg width="26" height="29" className={cls(className)} viewBox="0 0 26 29" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.9167 3.25H16.995C16.1875 1.04 13.7508 -0.0933373 11.5833 0.699996C10.365 1.125 9.45833 2.06 9.005 3.25H3.08333C2.33189 3.25 1.61122 3.54851 1.07986 4.07986C0.548511 4.61121 0.25 5.33188 0.25 6.08333V25.9167C0.25 26.6681 0.548511 27.3888 1.07986 27.9201C1.61122 28.4515 2.33189 28.75 3.08333 28.75H22.9167C23.6681 28.75 24.3888 28.4515 24.9201 27.9201C25.4515 27.3888 25.75 26.6681 25.75 25.9167V6.08333C25.75 5.33188 25.4515 4.61121 24.9201 4.07986C24.3888 3.54851 23.6681 3.25 22.9167 3.25ZM13 3.25C13.3757 3.25 13.7361 3.39925 14.0017 3.66493C14.2674 3.9306 14.4167 4.29094 14.4167 4.66666C14.4167 5.04239 14.2674 5.40272 14.0017 5.6684C13.7361 5.93407 13.3757 6.08333 13 6.08333C12.6243 6.08333 12.2639 5.93407 11.9983 5.6684C11.7326 5.40272 11.5833 5.04239 11.5833 4.66666C11.5833 4.29094 11.7326 3.9306 11.9983 3.66493C12.2639 3.39925 12.6243 3.25 13 3.25ZM5.91667 8.91666H20.0833V6.08333H22.9167V25.9167H3.08333V6.08333H5.91667V8.91666ZM20.0833 14.5833H5.91667V11.75H20.0833V14.5833ZM17.25 20.25H5.91667V17.4167H17.25V20.25Z" />
    </svg>
    {productInCart && (
      <svg className="absolute -right-2 -top-2 h-2 w-2" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="4" cy="4" r="4" fill="#97D700" />
      </svg>
    )}
  </div>
);

const SearchIcon = () => (
  <svg width="26" height="26" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.45833 0.25C11.9005 0.25 14.2427 1.22016 15.9696 2.94706C17.6965 4.67396 18.6667 7.01613 18.6667 9.45833C18.6667 11.7392 17.8308 13.8358 16.4567 15.4508L16.8392 15.8333H17.9583L25.0417 22.9167L22.9167 25.0417L15.8333 17.9583V16.8392L15.4508 16.4567C13.8358 17.8308 11.7392 18.6667 9.45833 18.6667C7.01613 18.6667 4.67396 17.6965 2.94706 15.9696C1.22016 14.2427 0.25 11.9005 0.25 9.45833C0.25 7.01613 1.22016 4.67396 2.94706 2.94706C4.67396 1.22016 7.01613 0.25 9.45833 0.25ZM9.45833 3.08333C5.91667 3.08333 3.08333 5.91667 3.08333 9.45833C3.08333 13 5.91667 15.8333 9.45833 15.8333C13 15.8333 15.8333 13 15.8333 9.45833C15.8333 5.91667 13 3.08333 9.45833 3.08333Z" />
  </svg>
);

export const HamburgerIcon = () => (
  <svg width="22" height="14" className="absolute right-6 top-7" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.5 0H21.5V2.33333H0.5V0ZM0.5 5.83333H21.5V8.16667H0.5V5.83333ZM0.5 11.6667H21.5V14H0.5V11.6667Z" fill="black" />
  </svg>
);

export const CloseIcon = () => (
  <svg width="18" height="18" className="absolute right-6 top-6" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.1666 2.47825L15.5216 0.833252L8.99992 7.35492L2.47825 0.833252L0.833252 2.47825L7.35492 8.99992L0.833252 15.5216L2.47825 17.1666L8.99992 10.6449L15.5216 17.1666L17.1666 15.5216L10.6449 8.99992L17.1666 2.47825Z"
      fill="black"
    />
  </svg>
);

const Logo = () => (
  <a href="/" className="flex flex-shrink-0 items-center py-2 sm:py-3">
    <img className="block w-32 px-4 lg:hidden" src="/assets/frontend/logo.png" alt="Ironman 4X4 mobile" />
    <img className="hidden w-32 lg:block xl:w-auto" src="/assets/frontend/logo.png" alt="Ironman 4X4" />
  </a>
);

const DropdownEffects = ({ open }: { open: boolean }) => {
  if (open) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    return <div className="fixed inset-0 z-40 bg-gray-900/50" aria-hidden="true" />;
  }
  return null;
};

const MenuWrapper = ({ children, className, menuWidth = navBarWidth }: { className?: string; menuWidth?: string }) => (
  <Transition
    as={Fragment}
    enter="transition ease-out duration-200"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="transition ease-in duration-150"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <Popover.Panel className={cls('border-gray-450 absolute inset-x-0 top-full w-full border-t bg-white text-sm text-gray-500', className)}>
      <div className="relative">
        <div className={menuWidth}>{children}</div>
      </div>
    </Popover.Panel>
  </Transition>
);

const Search = ({ setOpen }) => {
  const formikProps = {
    initialValues: {
      search: '',
    },
    // @ts-ignore
    onSubmit: (data) => {
      window.location.href = '/search-page?' + 'search=' + data.search;
    },
  };

  return (
    <Popover className="flex" open={true}>
      {({ open }) => {
        useEffect(() => {
          setOpen(open);
        }, [open]);

        return (
          <>
            <div className="relative flex">
              <Popover.Button
                className={cls(
                  'flex items-center space-x-2 border-b-4 border-transparent py-4 text-sm font-bold uppercase focus:border-black focus:outline-none min-[1400px]:text-base',
                )}
              >
                <span className="sr-only">Search</span>
                <SearchIcon />
              </Popover.Button>
            </div>

            <MenuWrapper className="py-14">
              <Formik initialValues={formikProps.initialValues} onSubmit={formikProps.onSubmit}>
                {({ handleSubmit }) => (
                  <form onSubmit={handleSubmit} className="flex w-full flex-col items-center space-y-5">
                    <div className="xl:w-3/5">
                      <h2 className="mb-3.5 text-4xl uppercase text-black">Search Products</h2>
                      <div className="my-10 flex flex-col lg:flex-row lg:space-x-5">
                        <TextField className="w-full" title="Search" name="search" invertColor />
                        <PrimaryButton className="-mb-10 mt-7 w-full text-center lg:mb-0 lg:mt-0 lg:w-64" text="Search" type="submit" />
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </MenuWrapper>
          </>
        );
      }}
    </Popover>
  );
};

const FitMy4x4 = ({ setOpen }) => {
  const [allMakes, setAllMakes] = useState<Make[] | []>([]);
  const [allModels, setAllModels] = useState<Model[] | []>([]);
  const [allSeries, setAllSeries] = useState<Series[] | []>([]);

  useEffect(() => {
    getMakes(setAllMakes);
  }, []);

  return (
    <Popover className="flex" open={true}>
      {({ open }) => {
        useEffect(() => {
          setOpen(open);
        }, [open]);

        return (
          <>
            <div className="relative flex">
              <Popover.Button
                className={cls(
                  'flex items-center space-x-2 py-4 text-sm font-bold uppercase focus:border-black focus:outline-none xl:text-base',
                  open ? 'border-b-4 border-black' : 'border-b-4 border-transparent',
                )}
              >
                <span>Fit my 4X4</span>
                <DropDownIcon open={open} />
              </Popover.Button>
            </div>

            <MenuWrapper className="py-14">
              <h2 className="mb-3.5 text-4xl uppercase text-black">Fit my 4x4</h2>
              <Formik initialValues={fitMy4X4FormikProps().initialValues()} onSubmit={fitMy4X4FormikProps().onSubmit}>
                {({ handleSubmit, values }) => {
                  useEffect(() => {
                    getModels(values.make, setAllModels);
                  }, [values.make]);

                  useEffect(() => {
                    getSeries(values.make, values.model, setAllSeries);
                  }, [values.make, values.model]);

                  return (
                    <form className="flex flex-col lg:flex-row lg:space-x-5" onSubmit={handleSubmit}>
                      <SelectField
                        title="Make"
                        name="make"
                        options={allMakes.map((make) => ({
                          value: make.ID,
                          label: make.Name,
                        }))}
                        invertColor
                      />
                      <SelectField
                        title="Model"
                        name="model"
                        options={allModels.map((model) => ({
                          value: model.ID,
                          label: model.Name,
                        }))}
                        invertColor
                      />
                      <SelectField
                        title="Series"
                        name="series"
                        options={allSeries.map((series) => ({
                          value: series.ID,
                          label: series.Name,
                        }))}
                        invertColor
                      />
                      {/*@ts-ignore*/}
                      <SecondaryButton as="a" href="fit-my-4x4?clear=1" className="flex justify-center space-x-4" type="submit">
                        <span>Clear</span>
                      </SecondaryButton>
                      <PrimaryButton className="text-center" text="Apply" type="submit" />
                    </form>
                  );
                }}
              </Formik>
            </MenuWrapper>
          </>
        );
      }}
    </Popover>
  );
};

const MobileNav = ({ open, setOpen, links, footerLinks }: { footerLinks: FooterLink[]; links: Link[] }) => {
  const titleClasses = 'font-bold text-white uppercase tracking-widest';
  const productClasses = 'text-white ml-6';

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50 lg:hidden" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 z-40 flex">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-y-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-y-full"
          >
            <Dialog.Panel className="relative flex w-full flex-col overflow-y-auto bg-black pb-12 shadow-xl">
              <button onClick={() => setOpen(false)} type="button" className="fixed right-0 block h-mobile w-mobile bg-green lg:hidden">
                <CloseIcon />
              </button>

              <div className="flex flex-col space-y-6 px-4 py-6">
                {links.map(({ title, link, children }) => (
                  <Fragment key={link}>
                    <a href={link} style={link === window.location.pathname ? { borderColor: 'currentColor' } : {}} className={titleClasses}>
                      {title}
                    </a>
                    {children.length > 0 && (
                      <div className="-mt-3 flex flex-col space-y-3">
                        {children
                          .filter((link2: Link) => !link2.filterOut)
                          ?.map(({ title, link }) => (
                            <a href={link} key={link} className={productClasses}>
                              {title}
                            </a>
                          ))}
                      </div>
                    )}
                  </Fragment>
                ))}
                <a href="/dealers" className={titleClasses}>
                  Dealer Login
                </a>
                {footerLinks.map(
                  ({ title, link }) =>
                    !links.map(({ title }) => title).includes(title) && (
                      <a href={link} key={link} className={titleClasses}>
                        {title}
                      </a>
                    ),
                )}
              </div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

interface Link {
  title: string;
  link: string;
  navImageSrc: string;
  filterOut: boolean;
  children: Link[];
}

interface FooterLink {
  title: string;
  link: string;
}

const DropdownMenu = ({ setOpen, link }) => {
  const [currentMenuHover, setCurrentMenuHover] = useState('');
  const [childLinks, setChildLinks] = useState([]);
  const [image, setImage] = useState(link.navImageSrc);

  useEffect(() => {
    if (currentMenuHover) {
      const hoveredLink = link.children.find((child: Link) => child.title === currentMenuHover);
      setChildLinks(hoveredLink.children?.filter((link3: Link) => !link3.filterOut));
      setImage(hoveredLink.navImageSrc);
    }
  }, [currentMenuHover]);
  const navColumnClasses = 'flex flex-col 2xl:w-1/4 xl:w-1/3  w-1/2 py-2';
  return (
    <Popover className="flex">
      {({ open }) => {
        useEffect(() => {
          setOpen(open);
        }, [open]);

        return (
          <>
            <div className="relative flex">
              <Popover.Button
                className={cls(
                  'flex items-center space-x-2 border-b-4 py-4 text-sm font-bold uppercase focus:border-black focus:outline-none min-[1400px]:text-base',
                  open ? 'border-black' : 'border-transparent',
                )}
              >
                <span>{link.title}</span>
                <DropDownIcon open={open} />
              </Popover.Button>
            </div>

            <MenuWrapper menuWidth="ml-24 pl-2 lg:pl-8">
              <div className="flex divide-x">
                <div className={navColumnClasses}>
                  {link.children
                    ?.filter((link2: Link) => !link2.filterOut)
                    ?.map((link2: Link) => {
                      // if (link2.children?.filter((link3: Link) => !link3.filterOut)?.length > 0) {
                      return (
                        <a
                          href={link2.link}
                          key={link2.title}
                          onMouseEnter={() => setCurrentMenuHover(link2.title)}
                          className={cls(
                            'py-2 text-lg',
                            link2.title === currentMenuHover || currentMenuHover === '' ? 'text-black' : 'text-gray-500',
                            link2.title === currentMenuHover ? 'font-light' : '',
                          )}
                        >
                          {link2.title}
                        </a>
                      );
                    })}
                </div>
                <div className={cls(navColumnClasses, 'flex')}>
                  {childLinks?.map((link3: Link) => (
                    <a href={link3.link} key={link3.link} className="px-10 py-2 text-lg text-black">
                      {link3.title}
                    </a>
                  ))}
                </div>
                <div style={{ backgroundImage: `url(${image})` }} className="h-auto w-full bg-cover bg-center" />
              </div>
            </MenuWrapper>
          </>
        );
      }}
    </Popover>
  );
};

export function NavBar({
  whiteNav,
  links,
  productsInQuote,
  footerLinks,
}: {
  whiteNav: boolean;
  links: Link[];
  productsInQuote: boolean;
  footerLinks: FooterLink[];
}) {
  const [menuState, dispatchMenuEvent] = useReducer(
    (prevState, action) => {
      if (action.action === 'close') {
        return { ...prevState, open: undefined };
      }
      if (action.action === 'open') {
        return { ...prevState, open: true };
      }
    },
    null,
    () => ({
      menus: ['fitMy4x4'].concat(links.filter((link) => link.children.length > 0).map(({ title }: Link) => title)),
      open: undefined,
    }),
  );

  const [mobileNavOpen, setMobileNavOpen] = useState(false);

  return (
    <>
      <MobileNav open={mobileNavOpen} setOpen={setMobileNavOpen} links={links} footerLinks={footerLinks} />

      <DropdownEffects open={!!menuState.open} />
      <div />
      <div
        className={cls(
          'absolute top-0 z-50 w-full bg-black focus-within:bg-white',
          !!menuState.open || whiteNav ? 'lg:bg-white' : 'lg:bg-transparent lg:hover:bg-white',
        )}
        id="header"
      >
        <div
          className={cls(
            'h-mobile fill-slate-350 transition duration-200 focus-within:fill-black focus-within:text-black lg:h-auto',
            !!menuState.open || whiteNav
              ? 'lg:fill-black'
              : 'text-white fill-slate-350 lg:border-white lg:fill-green lg:hover:border-black lg:hover:fill-black lg:hover:text-black',
          )}
        >
          <div className={navBarWidth}>
            <div className="flex flex-1 items-center justify-between sm:items-stretch">
              <Logo />
              <Popover.Group className="hidden sm:ml-6 lg:block lg:self-stretch">
                <nav className="flex h-full lg:space-x-6 xl:space-x-8 2xl:space-x-20">
                  <FitMy4x4 setOpen={(open: boolean | undefined) => dispatchMenuEvent({ action: open ? 'open' : 'close', item: 'fitMy4x4' })} />
                  {links.map((link) => {
                    if (link.children.length > 0) {
                      return (
                        <DropdownMenu
                          key={link.title}
                          setOpen={(open: boolean | undefined) =>
                            dispatchMenuEvent({
                              action: open ? 'open' : 'close',
                              item: link.title,
                            })
                          }
                          link={link}
                        />
                      );
                    }
                    return (
                      <a
                        href={link.link}
                        key={link.title}
                        style={link.link === window.location.pathname ? { borderColor: 'currentColor' } : {}}
                        className={cls(
                          'flex items-center py-4 text-sm font-bold uppercase min-[1400px]:text-base',
                          link.link === window.location.pathname ? 'border-b-4' : 'border-b-4 border-transparent',
                        )}
                      >
                        <span>{link.title}</span>
                      </a>
                    );
                  })}
                </nav>
              </Popover.Group>
              <div className="-mr-2 mb-auto mt-auto flex h-mobile space-x-6 xl:space-x-9">
                <a className="pt-4 hover:cursor-pointer" href="/online-quote">
                  <span className="sr-only">Online Quote</span>
                  <CartIcon className="fill-inherit" productInCart={productsInQuote} />
                </a>

                <Search setOpen={(open: boolean | undefined) => dispatchMenuEvent({ action: open ? 'open' : 'close', item: 'search' })} />

                <button onClick={() => setMobileNavOpen(true)} type="button" className="relative block h-mobile w-mobile bg-green lg:hidden">
                  <HamburgerIcon />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

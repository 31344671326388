import cls from 'classnames';
import { forwardRef, InputHTMLAttributes } from 'react';

export const TextField = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & {
    dealerTextField?: boolean;
    invertColor?: boolean;
  }
>(({ title, className, dealerTextField = false, invertColor = false, ...props }, ref) => {
  let wrapperClasses = cls('border-b border-gray-600 z-10', className);
  let inputClasses = 'w-full outline-0 border-0 py-4 placeholder-gray-500';

  if (invertColor) {
    wrapperClasses = cls(wrapperClasses, 'hover:border-black');
    inputClasses = cls(inputClasses, 'hover:placeholder-black');

    if (dealerTextField) {
      wrapperClasses = cls(wrapperClasses, 'dark:hover:border-white');
      inputClasses = cls(inputClasses, 'dark:bg-black-dealers dark:text-white bg-white dark:hover:placeholder-white text-black');
    } else {
      inputClasses = cls(inputClasses, 'bg-white');
    }
  } else {
    wrapperClasses = cls(wrapperClasses, 'hover:border-white');
    inputClasses = cls(inputClasses, 'bg-black text-white hover:placeholder-white');
  }

  return (
    <div className={wrapperClasses}>
      <input className={inputClasses} placeholder={title} ref={ref} {...props} />
    </div>
  );
});

import cls from 'classnames';
import { ReactNode } from 'react';

export const classes = 'z-20 hover:cursor-pointer bg-green px-12 py-4 text-black font-extrabold w-full uppercase hover:opacity-80 disabled:opacity-60';

export const PrimaryButton = ({
  text,
  children,
  as = 'button',
  className,
  ...props
}: {
  as?: string;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  onClick?: (e: any) => void;
  props?: any;
  text?: string;
  type?: string;
}) => {
  const Element = as;
  return (
    <Element className={cls(classes, className)} {...props}>
      {text}
      {children}
    </Element>
  );
};

'use strict';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import * as Accordion from '@radix-ui/react-accordion';
import cls from 'classnames';

interface AccordionLine {
  title: string;
  content: string;
}

export const AccordionBlock = ({ rows, containsImage, darkTheme }: { rows: AccordionLine[]; containsImage: boolean; darkTheme: boolean }) => (
  <div className={cls('flex flex-col', containsImage ? 'xl:w-155 w-full' : 'w-full')}>
    <Accordion.Root className={cls('flex w-full flex-col', containsImage ? 'lg:w-155 mx-auto xl:mx-0' : 'lg:w-3/5')} type="single" collapsible>
      {rows.map((question, index) => (
        <Accordion.Item
          value={'item-' + index}
          key={index}
          className={cls(
            'data-[state=open]:shadow-home data-[state=open]:rounded-box duration-300 data-[state=closed]:border-b',
            darkTheme ? 'border-gray-600' : 'border-black/10',
          )}
        >
          <div className="flex space-x-6">
            <div className="ml-9 flex w-full flex-col">
              <Accordion.Header
                className={cls('flex items-center justify-between py-8 text-xl font-bold uppercase', darkTheme ? 'text-white' : 'text-black')}
              >
                {question.title}
                <Accordion.Trigger className={cls('AccordionTrigger focus-within:border-0 focus:border-0', darkTheme && 'darkTheme')}>
                  <ChevronDownIcon className={cls('AccordionChevron h-6 w-6 hover:text-green', darkTheme ? 'text-white' : 'text-black')} />
                </Accordion.Trigger>
              </Accordion.Header>

              <Accordion.Content className="overflow-hidden data-[state=closed]:animate-showContent data-[state=open]:animate-hideContent">
                <div className="prose prose-sm pb-9 pr-9 lg:prose-lg sm:pr-28 2xl:pr-40">
                  <div className={cls(darkTheme ? 'text-white' : 'text-black')} dangerouslySetInnerHTML={{ __html: question.content }} />
                </div>
              </Accordion.Content>
            </div>
          </div>
        </Accordion.Item>
      ))}
    </Accordion.Root>
  </div>
);

import axios from 'axios';
import cls from 'classnames';
import { useFormikContext } from 'formik';
import { ChangeEvent, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { haversineDistance } from '../services/haversine';
import { CityOrPostcodeField } from './FormFields/CityOrPostcodeField';
import { SelectField } from './FormFields/SelectField';
import PageWidthConstraint from './PageWidthConstraint';
import { StoreList } from './StoreList';
import { StoresMap } from './StoresMap';

const setEmptyLabel = (data: []) =>
  data.map((row: { label: string }) => {
    if (row.label === '') {
      row.label = '-- Please select --';
    }
    return row;
  });

export const getStoreTypes = (setStoreTypes: {
  (value: SetStateAction<never[]>): void;
  (value: SetStateAction<never[]>): void;
  (arg0: { label: string }[]): void;
}) => {
  axios.get('/api/dealers/storeTypeOptions').then((response) => {
    setStoreTypes(setEmptyLabel(response.data));
  });
};

export const getServices = (setServices: {
  (value: SetStateAction<never[]>): void;
  (value: SetStateAction<never[]>): void;
  (arg0: { label: string }[]): void;
}) => {
  axios.get('/api/dealers/serviceOptions').then((response) => {
    setServices(setEmptyLabel(response.data));
  });
};

const distanceOptions = [
  { value: 10, label: '10KM' },
  { value: 20, label: '20KM' },
  { value: 50, label: '50KM' },
  { value: 100, label: '100KM' },
];

const FilterWrapper = ({ hasStoreList, children, className }: { hasStoreList: boolean; children: ReactNode; className: string }) =>
  hasStoreList ? <PageWidthConstraint className={className}>{children}</PageWidthConstraint> : <div className={className}>{children}</div>;

export const FindAStore = ({
  apiKey,
  hasStoreList = true,
  darkHeader = true,
  name = null,
  setSelectDealer,
  dealerID,
}: {
  apiKey: string;
  hasStoreList?: boolean;
  setSelectDealer?: (p: any) => {};
  dealerID?: number;
  darkHeader: boolean;
  name?: string | null;
}) => {
  const [stores, setStores] = useState([]);
  const [displayStores, setDisplayStores] = useState([]);
  const [hovering, setHovering] = useState('');
  const [clicked, setClicked] = useState('');
  const [store, setStore] = useState('');
  const [address, setAddress] = useState('');
  const [coordinates, setCoordinates] = useState<{
    lat: number;
    lng: number;
  } | null>(null);
  const [service, setService] = useState('');
  const [services, setServices] = useState([]);
  const [storeTypes, setStoreTypes] = useState([]);
  const [distance, setDistance] = useState(20);

  useEffect(() => {
    axios.get('/api/dealers').then(function (response) {
      setStores(response.data);
      setDisplayStores(response.data);

      if (dealerID) {
        setSelectDealer(response.data.find((item: { id: number }) => item.id === dealerID) || null);
      }

      window.location.search.split('&').forEach((param) => {
        const keyValueArray = param.split('=');
        if (keyValueArray[1]) {
          switch (keyValueArray[0].replace('?', '')) {
            case 'store':
              setStore(String(keyValueArray[1]));
              break;
            case 'address':
              setAddress(keyValueArray[1]);
              break;
            case 'service':
              setService(keyValueArray[1]);
              break;
          }
        }
      });
    });
    getServices(setServices);
    getStoreTypes(setStoreTypes);
  }, []);

  const formikContext = useFormikContext();

  useEffect(() => {
    if (formikContext && name) {
      formikContext.setFieldValue(name, clicked);
    }
  }, [clicked]);

  useEffect(() => {
    setDisplayStores(
      stores?.filter((value) => {
        if (store !== '' && value.storeType !== store) return false;

        if (coordinates) {
          if (!value.lat || !value.long) return false;

          const storeDistance = haversineDistance(coordinates, { lat: value.lat, lng: value.long });

          if (storeDistance > distance) return false;
        }

        if (service !== '' && !value.services.includes(service)) return false;

        return true;
      }),
    );
  }, [store, address, service, coordinates, distance]);

  return (
    <div className="w-full">
      <div className={cls('w-full lg:py-10', darkHeader ? 'bg-black' : 'bg-white')}>
        <FilterWrapper hasStoreList={hasStoreList} className="flex flex-col lg:flex-row lg:gap-10">
          <div className="flex shrink-0 lg:w-1/3">
            <div className="grow">
              <CityOrPostcodeField
                title="City or Postcode"
                className="w-full"
                invertColor={!darkHeader}
                onGeoCode={(coords) => setCoordinates(coords.lat ? coords : null)}
              />
            </div>
            <div className="w-20 shrink grow-0">
              <SelectField
                title="Distance"
                value={distance}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setDistance(e.target.value)}
                options={distanceOptions}
                invertColor={!darkHeader}
              />
            </div>
          </div>
          <SelectField
            title="Store Type"
            value={store}
            clear={() => setStore('')}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setStore(e.target.value)}
            options={storeTypes}
            invertColor={!darkHeader}
          />
          <SelectField
            title="Service"
            value={service}
            clear={() => setService('')}
            onChange={(e: ChangeEvent<HTMLSelectElement>) => setService(e.target.value)}
            options={services}
            invertColor={!darkHeader}
          />
        </FilterWrapper>
      </div>
      <div className="flex w-full flex-col lg:flex-row">
        {hasStoreList ? (
          <div className="relative flex w-full flex-row lg:h-screen">
            <div className="x-4 ml-auto flex max-h-screen w-full flex-col items-center overflow-y-auto scroll-smooth sm:pl-4 lg:py-16 lg:pl-8 lg:pr-20 xl:w-3/4">
              {displayStores?.map((value) => <StoreList key={value.id} value={value} hovering={hovering} />)}
            </div>
          </div>
        ) : (
          <div className="relative flex w-full flex-row">
            <div className="ml-auto flex max-h-screen w-full flex-col items-center overflow-y-auto scroll-smooth">
              {displayStores?.map((value) => (
                <button type="button" key={value.id} className="w-full text-left" onClick={() => setSelectDealer(value)}>
                  <StoreList hasStoreList={hasStoreList} value={value} hovering={hovering} />
                </button>
              ))}
            </div>
          </div>
        )}
        <div className="order-first h-slider w-full lg:order-last lg:h-screen">
          <StoresMap
            pins={displayStores}
            center={coordinates || undefined}
            setHovering={setHovering}
            apiKey={apiKey}
            setClicked={setClicked}
            radius={distance}
          />
        </div>
      </div>
    </div>
  );
};

// @ts-ignore
//library exist but ts is packing a poo
import GoogleMapReact from 'google-map-react';

import { useEffect, useRef, useState } from 'react';

const CustomPin = ({ pin, setHovering, setClicked, ...props }: { pin: any; setHovering: any; setClicked: any; $hover?: boolean }) => {
  useEffect(() => {
    if (props.$hover) {
      setHovering(pin.address);
    } else {
      setHovering('');
    }
  }, [props.$hover]);

  const address = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(pin.address)}`;
  return (
    <div className="relative">
      {props.$hover ? (
        <div className="absolute bottom-32 z-10 flex h-20 -translate-x-1/2 flex-col justify-between rounded bg-white p-2 text-xxs shadow-xl">
          <div className="whitespace-nowrap text-xs font-bold text-gray-600">{pin.name}</div>
          <a className="text-green hover:underline" href={address} target="_blank">
            {pin.address}
          </a>
          <div className="text-gray-600">{pin.description}</div>
        </div>
      ) : null}
      <img
        src={'images/IronManPin.svg'}
        onClick={() => setClicked(pin)}
        className="h-14 w-14 -translate-x-1/2 -translate-y-full"
        alt="Ironman 4x4 Pin logo"
      />
    </div>
  );
};

const defaultCenter = {
  lat: -41.2924,
  lng: 174.7787,
};

export const StoresMap = ({
  pins,
  apiKey,
  setHovering,
  setClicked,
  radius,
  center: customCenter,
}: {
  pins: {
    lat: number;
    long: number;
  }[];
  apiKey: string;
  setHovering?: any;
  setClicked?: any;
  radius?: number;
  center?: {
    lat: number;
    lng: number;
  };
}) => {
  const defaultMapProps = {
    hoverRadius: 60,
  };

  const zoom = customCenter && radius ? { 10: 12, 20: 11, 50: 10, 100: 9 }[radius] : 6;

  const center = customCenter || defaultCenter;

  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
  const circleRef = useRef<google.maps.Circle | null>(null);

  useEffect(() => {
    if (!mapRef) return;

    mapRef.setCenter(customCenter || defaultCenter);
    mapRef.setZoom(zoom);

    if (!radius || !customCenter) {
      if (circleRef.current) {
        circleRef.current.setVisible(false);
      }
    } else if (circleRef.current) {
      circleRef.current.setRadius(radius * 1000);
      circleRef.current.setCenter(center);
      circleRef.current.setVisible(true);
    } else {
      circleRef.current = new window.google.maps.Circle({
        strokeColor: '#5999de',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#9ec5ee',
        fillOpacity: 0.35,
        map: mapRef,
        center: center,
        radius: radius * 1000,
      });
    }
  }, [radius, mapRef, zoom, center.lat, center.lng, customCenter?.lat, customCenter?.lng]);

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: apiKey, libraries: ['places'] }}
      defaultCenter={center}
      defaultZoom={zoom}
      hoverDistance={defaultMapProps.hoverRadius}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map }: { map: google.maps.Map }) => {
        setMapRef(map);
      }}
    >
      {// @ts-ignore
      //the library uses lat and lng but ts can not see that these props need to be applied
      pins?.map((pin) => <CustomPin key={pin.name} lat={pin.lat} lng={pin.long} pin={pin} setHovering={setHovering} setClicked={setClicked} />)}
    </GoogleMapReact>
  );
};

import { useField, useFormikContext } from 'formik';
import { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import { TextField as CustomTextField } from '../FormFields/TextField';

export const TextField = ({
  title,
  name,
  ...props
}: {
  autoComplete?: string;
  className?: string;
  dealerTextField?: boolean;
  defaultValue?: number;
  invertColor?: boolean;
  min?: number;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  title: string;
  type?: string;
}) => {
  const { setFieldValue, errors, isSubmitting } = useFormikContext<any>();
  const [{ value, onChange }] = useField(name);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  useEffect(() => {
    if (isSubmitting) {
      setHasSubmitted(isSubmitting);
    }
  }, [isSubmitting]);

  return (
    <>
      <CustomTextField title={title} value={value} onChange={(e: ChangeEvent<HTMLInputElement>) => setFieldValue(name, e.target.value)} {...props} />
      {errors[name] && typeof errors[name] === 'string' && hasSubmitted ? <p className="text-red-450">{errors[name]}</p> : null}
    </>
  );
};

'use strict';
import { useEffect, useState } from 'react';

const toTitleCase = (text: string) =>
  text
    .replace(/-|\s/g, ' ')
    .toLowerCase()
    .split(' ')
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

export function BreadCrumbs({ ignore, endText }: { ignore?: string[]; endText?: string }) {
  const [routeNames, setRouteNames] = useState<string[]>([]);
  useEffect(() => {
    setRouteNames(
      window.location.pathname
        .split('/')
        .filter((n) => n)
        .filter((item) => !ignore?.includes(item)),
    );
  }, [window.location.pathname]);

  if (routeNames.length > 1) {
    return (
      <div className="flex flex-wrap text-xs">
        {routeNames.map((route, index) =>
          index === routeNames.length - 1 ? (
            <p className="font-normal" key={index}>
              {endText ? endText : toTitleCase(route)}
            </p>
          ) : (
            <div className="flex" key={index}>
              <a href={window.location.pathname.split(route)[0] + route} className="font-light">
                {toTitleCase(route)}
              </a>
              <p className="-mt-2 px-4 text-lg text-green">/</p>
            </div>
          ),
        )}
      </div>
    );
  }
  return null;
}

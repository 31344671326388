'use strict';
import axios from 'axios';
import { Formik, FormikProps } from 'formik';
import { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { SelectField } from './FormikFields/SelectField';
import { PrimaryButton } from './PrimaryButton';
import { SecondaryButton } from './SecondaryButton';

export interface Make {
  ClassName: string;
  Created: string;
  ID: string;
  LastEdited: string;
  Name: string;
  RecordClassName: string;
  Version: number;
}

export interface Model {
  ClassName: string;
  Created: string;
  ID: string;
  MakeID: string;
  LastEdited: string;
  Name: string;
  RecordClassName: string;
  Version: number;
}

export interface Series {
  ClassName: string;
  Created: string;
  ID: string;
  ModelID: string;
  LastEdited: string;
  Name: string;
  RecordClassName: string;
  Version: number;
}

export const getMakes = (setAllMakes: (data: []) => void) => {
  axios.get('/api/fitmy4x4/make').then(function (response) {
    setAllMakes(response.data);
  });
};

export const getModels = (make: string | null, setAllModels: (data: []) => void) => {
  if (make) {
    axios.get('/api/fitmy4x4/model', { params: { makeID: make } }).then(function (response) {
      setAllModels(response.data);
    });
  } else {
    setAllModels([]);
  }
};

export const getSeries = (make: string | null, model: string | null, setAllSeries: (data: []) => void) => {
  if (make && model) {
    axios.get('/api/fitmy4x4/series', { params: { modelID: model } }).then(function (response) {
      setAllSeries(response.data);
    });
  } else {
    setAllSeries([]);
  }
};

export const fitMy4X4FormikProps = (redirect = false) => ({
  initialValues: (): {} => {
    const vehicle: HTMLElement | null = document.querySelector('[data-react="vehicle"]');
    return vehicle?.dataset?.props ? JSON.parse(vehicle.dataset.props) : {};
  },
  // @ts-ignore
  onSubmit: (data) => {
    window.location.href =
      '/fit-my-4x4?' +
      (redirect ? 'redirect-back=1&' : '') +
      (data.make ? 'make=' + data.make + (data.model ? '&model=' + data.model + (data.series ? '&series=' + data.series : '') : '') : '');
  },
});

export const FitMy4x4 = () => {
  const [allMakes, setAllMakes] = useState<Make[] | []>([]);
  const [allModels, setAllModels] = useState<Model[] | []>([]);
  const [allSeries, setAllSeries] = useState<Series[] | []>([]);

  useEffect(() => {
    getMakes(setAllMakes);
  }, []);

  return (
    <Formik initialValues={fitMy4X4FormikProps().initialValues()} onSubmit={fitMy4X4FormikProps().onSubmit}>
      {({ handleSubmit, values }: FormikProps<any>) => {
        useEffect((): void => {
          getModels(values.make, setAllModels);
        }, [values.make]);

        useEffect((): void => {
          getSeries(values.make, values.model, setAllSeries);
        }, [values.make, values.model]);

        return (
          <form className="flex flex-col lg:mt-10 lg:flex-row lg:space-x-5" onSubmit={handleSubmit}>
            <SelectField title="Make" name="make" options={allMakes.map((make) => ({ value: make.ID, label: make.Name }))} />
            <SelectField title="Model" name="model" options={allModels.map((model) => ({ value: model.ID, label: model.Name }))} />
            <SelectField title="Series" name="series" options={allSeries.map((series) => ({ value: series.ID, label: series.Name }))} />
            {/*@ts-ignore*/}
            <div className="mt-6 flex w-full shrink-0 lg:mt-0 lg:w-2/5">
              <SecondaryButton as="a" href="fit-my-4x4?clear=1" className="relative w-6 pl-8 pr-12" type="submit">
                <svg width="18" height="18" className="-ml-2 mt-1 fill-green" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.1666 2.47825L15.5216 0.833252L8.99992 7.35492L2.47825 0.833252L0.833252 2.47825L7.35492 8.99992L0.833252 15.5216L2.47825 17.1666L8.99992 10.6449L15.5216 17.1666L17.1666 15.5216L10.6449 8.99992L17.1666 2.47825Z" />
                </svg>
              </SecondaryButton>
              <PrimaryButton text="Apply" type="submit" />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

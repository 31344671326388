import cls from 'classnames';
import { ChangeEventHandler } from 'react';

export const TextAreaField = ({
  title,
  onChange,
  invertColor = false,
  dealerTextField = false,
  ...props
}: {
  dealerTextField?: boolean;
  invertColor?: boolean;
  name?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  title: string;
}) => (
  <div className={cls('z-10 w-full border-b border-gray-600', invertColor ? 'hover:border-black' : 'hover:border-white')}>
    <textarea
      className={cls(
        'w-full border-0 py-4 placeholder-gray-500 outline-0',
        invertColor ? 'bg-white text-black hover:placeholder-black' : 'text-white bg-black hover:placeholder-white',
      )}
      placeholder={title}
      //@ts-ignore the typing above is not quiet correct tp be used in this input but its a good enough type for the use case of this component
      onChange={onChange}
      {...props}
    />
  </div>
);

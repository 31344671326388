import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';

export type PresenceBlockProps = HTMLMotionProps<'section'> & {
  visible?: boolean;
};

export const PresenceBlock = ({ children, visible = false, ...props }: PresenceBlockProps) => (
  <AnimatePresence initial={false}>
    {visible && (
      <motion.section
        {...props}
        key="content"
        initial="collapsed"
        animate="open"
        exit="collapsed"
        variants={{
          open: { opacity: 1, scale: 1, height: 'auto' },
          collapsed: { opacity: 0, scale: 0.5, height: 0 },
        }}
        transition={{ duration: 0.2 }}
      >
        {children}
      </motion.section>
    )}
  </AnimatePresence>
);

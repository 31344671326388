import { ChangeEvent } from 'react';

export const RadioGroupField = ({
  onChange,
  options,
  name = 'radio-field',
  containerClassName = '',
}: {
  onChange: (e: ChangeEvent<HTMLSelectElement>) => void;
  options: { value: string; label: string }[];
  name?: string;
  containerClassName?: string;
}) => (
  <div className={containerClassName}>
    {options.map((option: any) => (
      <div key={option.value} className="flex items-center gap-4">
        {/*@ts-ignore*/}
        <input type="radio" onChange={onChange} value={option.value} name={name} className="h-4 w-4 border-white bg-green accent-green" />
        <span>{option.label}</span>
      </div>
    ))}
  </div>
);

import cls from 'classnames';
import { createElement, ReactNode } from 'react';

export const classes = 'z-20 hover:cursor-pointer bg-black px-12 py-4 text-green font-extrabold w-full uppercase hover:opacity-80';

export const SecondaryButton = ({
  children,
  as = 'button',
  className,
  ...props
}: {
  as?: string;
  children: ReactNode;
  className?: string;
  href?: string;
  props?: any;
  type?: string;
}) => createElement(as, { className: cls(classes, className), ...props }, children);

import Tippy from '@tippyjs/react';

export const Vehicles = ({ vehicles }: { vehicles: [] }) => (
  <Tippy
    theme="light"
    content={
      <>
        {vehicles.map((vehicle, index) => (
          <p>
            {vehicle}
            {index + 1 !== vehicles.length && ','}
          </p>
        ))}
      </>
    }
    delay={200}
    placement="bottom-end"
  >
    <button className="underline">{vehicles.length} Vehicles</button>
  </Tippy>
);

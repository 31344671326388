'use strict';
import { DialogTitle } from '@radix-ui/react-dialog';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { Dialog, DialogContent } from './Dialog';

export const NonDealerPriceDialog = () => (
  <Dialog open>
    <DialogContent>
      <DialogTitle className="hidden">Non Dealer Price</DialogTitle>
      <div className="flex flex-col gap-6">
        <h2 className="text-3xl font-normal">Warning: Dealer Price not selected</h2>
        <p>You are currently not under the dealer pricing. Please switch back to dealer pricing or view current Order</p>
        <div className="flex justify-end gap-4">
          <a href="/dealers/editOrder" className="text-white rounded-lg bg-black px-4 py-2">
            View Order
          </a>
          <a href={'/dealers/setPriceType?priceCode=' + encodeURIComponent('Dealer Price')} className="rounded-lg bg-green px-4 py-2">
            Switch to Dealer Pricing
          </a>
        </div>
      </div>
    </DialogContent>
  </Dialog>
);

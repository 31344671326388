import { useFormikContext } from 'formik';
import { RadioGroupField as CustomRadioGroupField } from '../FormFields/RadioGroupField';

export const RadioGroupField = ({
  options,
  invertColor = false,
  name,
  containerClassName = '',
  ...props
}: {
  options: { value: string; label: string }[];
  invertColor?: boolean;
  name: string;
  containerClassName?: string;
}) => {
  const { setFieldValue } = useFormikContext<any>();

  return (
    <CustomRadioGroupField
      onChange={(e) => setFieldValue(name, e.target.value)}
      options={options}
      containerClassName={containerClassName}
      {...props}
    />
  );
};

import { CogIcon } from '@heroicons/react/20/solid';
import axios from 'axios';
import cls from 'classnames';
import { Fragment, useEffect, useState } from 'react';
import { TextField } from './FormFields/TextField';
import { ImageDialog } from './ImageDialog';

type Product = {
  code: string;
  thumbnailImage?: string;
  name: string;
  dealerPriceNice: string;
  dealerPrice: number;
  available: number;
  stockLabel: string;
};

const ActionsFields = ({ productCode, price, stock, cartItem = null, tableProducts }) => {
  const [quantity, setQuantity] = useState(cartItem?.quantity ? cartItem.quantity : 1);
  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    if (cartItem?.quantity) {
      setQuantity(cartItem.quantity);
    }
  }, [cartItem]);

  useEffect(() => {
    setTotalPrice(quantity * price);
  }, [quantity, tableProducts]);

  const newProducts = (newProduct) => {
    const newProductEvent = new CustomEvent('addProduct', {
      detail: {
        product: newProduct,
      },
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    document.dispatchEvent(newProductEvent);
  };

  const removeProduct = (product) => {
    const removeProductEvent = new CustomEvent('removeProduct', {
      detail: {
        product: product,
      },
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    document.dispatchEvent(removeProductEvent);
  };

  return (
    <div className="flex w-full items-center justify-between space-x-6">
      <div className="ml-6 flex items-center space-x-6">
        <TextField
          title="qty"
          type="number"
          min={1}
          dealerTextField={true}
          className="w-14"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          invertColor
        />
        <div className="text-slate-450">{totalPrice.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</div>
      </div>

      {cartItem ? (
        <div className="flex space-x-4">
          <button
            type="button"
            onClick={() => newProducts({ code: productCode, price: price, quantity: quantity, stock: stock })}
            className="ml-auto rounded-lg bg-green px-4 py-2"
          >
            Update
          </button>
          <button
            type="button"
            onClick={() => removeProduct({ code: productCode, price: price, quantity: quantity })}
            className="ml-auto rounded-lg bg-red-450 px-4 py-2"
          >
            Remove from cart
          </button>
        </div>
      ) : (
        <button
          type="button"
          onClick={() => newProducts({ code: productCode, price: price, quantity: quantity, stock: stock })}
          className="ml-auto rounded-lg bg-green px-4 py-2"
        >
          Add to Cart
        </button>
      )}
    </div>
  );
};

const StockStatus = ({ stockStatus }: { stockStatus: [] }) => {
  const [stockStatusLabel, setStockStatusLabel] = useState('info');
  useEffect(() => {
    if (stockStatus[1]) {
      setStockStatusLabel(stockStatus[1]);
    }
  }, [stockStatus]);
  const sharedClasses = 'mx-4 font-light px-3 py-1 text-sm rounded-full text-white whitespace-nowrap';
  if (stockStatusLabel === 'warning') {
    return <span className={cls('bg-amber-350', sharedClasses)}>{stockStatus[0]}</span>;
  } else if (stockStatusLabel === 'error') {
    return <span className={cls('bg-red-450', sharedClasses)}>{stockStatus[0]}</span>;
  } else if (stockStatusLabel === 'success') {
    return <span className={cls('bg-green', sharedClasses)}>{stockStatus[0]}</span>;
  } else {
    return <span className={cls('bg-sky-400', sharedClasses)}>{stockStatus[0]}</span>;
  }
};

const PaginationButton = ({ pageNumber, type, link, generateTable }) => {
  let baseUrl = 'https://example.com';
  let dummyUrl = new URL(link, baseUrl);
  const start = new URLSearchParams(dummyUrl.search).get('start');

  let content;
  switch (type) {
    case 'next':
      content = '\u2192';
      break;
    case 'prev':
      content = '\u2190';
      break;
    default:
      content = pageNumber;
  }
  return <button onClick={() => generateTable('', start)}>{content}</button>;
};

export const OrderTable = () => {
  const [tableProducts, setTableProducts] = useState([]);
  const [tablePagination, setTablePagination] = useState(null);
  const [tableLoad, setTableLoad] = useState(false);
  const [products, setProducts] = useState(window?.initialOrder?.orderItems ? window?.initialOrder?.orderItems : []);

  useEffect(() => {
    document.addEventListener('updateProducts', function (event) {
      //@ts-ignore
      setProducts(event.detail.products);
    });
    generateTable('');
  }, []);

  useEffect(() => {
    const productUpdateEvent = new CustomEvent('updateProducts', {
      detail: {
        products: products,
      },
      bubbles: true,
      cancelable: true,
      composed: false,
    });
    document.dispatchEvent(productUpdateEvent);
  }, [JSON.stringify(products)]);

  const generateTable = (inputValue: string, start = 0) => {
    setTableLoad(true);
    axios.get(`/dealers/fetchProducts?search=${inputValue}&start=${start}`).then(function (response) {
      setTablePagination(response.data.pagination);
      setTableProducts(response.data.data);
      setTableLoad(false);
    });
  };

  if (!tablePagination) {
    //todo spinner or loading screen
    return null;
  }
  return (
    <div className="my-8 flex flex-col rounded-md bg-white p-6">
      <div className="flex w-full items-center justify-between">
        <h2 className="text-xl font-normal">Products</h2>
        <div className="mb-4 flex space-x-4">
          <TextField
            title="Search"
            autoFocus
            dealerTextField={true}
            onChange={(e) => {
              generateTable(e.target.value, 0);
            }}
            invertColor
          />
        </div>
      </div>
      <div className="relative">
        <table className="mt-3 w-full">
          <thead>
            <tr>
              <th className="mb-6 text-left text-xs font-light uppercase leading-none tracking-widest text-slate-450">Code</th>
              <th className="mb-6 text-left text-xs font-light uppercase leading-none tracking-widest text-slate-450">Name</th>
              <th className="mb-6 text-left text-xs font-light uppercase leading-none tracking-widest text-slate-450">Price</th>
              <th className="mb-6 text-left text-xs font-light uppercase leading-none tracking-widest text-slate-450">
                <span className="ml-4">Estimate Stock</span>
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            {tableProducts?.map((product: Product) => (
              <tr key={product.code}>
                <td className="flex items-center space-x-4 py-2 font-light">
                  {product.thumbnailImage ? (
                    <ImageDialog imageUrl={product.thumbnailImage}>
                      <div
                        className="h-10 w-10 rounded bg-white bg-contain bg-center bg-no-repeat"
                        style={{ backgroundImage: 'url(' + product.thumbnailImage + ')' }}
                      ></div>
                    </ImageDialog>
                  ) : (
                    <div className="h-10 w-10 rounded bg-white bg-contain bg-center bg-no-repeat" style={{ backgroundImage: undefined }}></div>
                  )}
                  <span className="pr-2">{product.code}</span>
                </td>
                <td className="text-slate-450">{product.name}</td>
                <td className="text-slate-450">{product.dealerPriceNice}</td>
                <td>
                  <StockStatus stockStatus={product.stockLabel} />
                </td>
                <td>
                  <ActionsFields
                    price={product.dealerPrice}
                    productCode={product.code}
                    stock={product.available}
                    cartItem={products.find((cartProduct) => cartProduct.code === product.code)}
                    tableProducts={tableProducts}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {tableLoad && (
          <>
            <div className="absolute top-0 z-10 h-full w-full bg-white opacity-60" />
            <CogIcon
              className="absolute bottom-0 left-0 right-0 top-0 z-30 mx-auto my-auto h-24 w-24 animate-spin"
              style={{ animationDuration: '3s' }}
            />
          </>
        )}
      </div>

      {Boolean(tablePagination.moreThanOnePage) && (
        <div className="mt-10 flex w-full justify-center space-x-6">
          {Boolean(tablePagination.notFirstPage) && (
            <PaginationButton pageNumber={null} type="prev" link={tablePagination.prevLink} generateTable={generateTable} />
          )}
          {tablePagination.paginationPages.map((paginationPage: any) => (
            <Fragment key={paginationPage.pageNumber}>
              {Boolean(paginationPage.currentBool) ? (
                <span className="underline">{paginationPage.pageNumber}</span>
              ) : Boolean(paginationPage.link) ? (
                <PaginationButton pageNumber={paginationPage.pageNumber} type="" link={paginationPage.link} generateTable={generateTable} />
              ) : (
                <span>...</span>
              )}
            </Fragment>
          ))}
          {Boolean(tablePagination.notLastPage) && (
            <PaginationButton pageNumber={null} type="next" link={tablePagination.nextLink} generateTable={generateTable} />
          )}
        </div>
      )}
    </div>
  );
};

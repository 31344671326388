'use strict';
import { mdiLoading } from '@mdi/js';
import { Icon } from '@mdi/react';
import * as Switch from '@radix-ui/react-switch';
import axios from 'axios';
import cls from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import React, { ChangeEvent, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { useMediaQuery } from 'usehooks-ts';
import { getMakes, getModels, getSeries, Make, Model, Series } from './FitMy4x4';
import { SelectField } from './FormFields/SelectField';
import { Option } from './FormikFields/SelectField';
import { ArrowLeft, ArrowRight } from './ItemSlider';
import { ProductSlide, Slide } from './ProductSlide';

type Filter = {
  title: string;
  filterID: string;
  values: Option[];
};

export function ProductSlider({
  slides: initialSlides,
  placeholderImage,
  filters,
  fitMy4x4,
}: {
  slides: ProductSlide[];
  initialSlides: ProductSlide[];
  placeholderImage?: string;
  filters: Filter[];
  fitMy4x4: boolean;
}) {
  const [slides, setSlides] = useState<ProductSlide[]>(initialSlides);
  const [showComponents, setShowComponents] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(fitMy4x4);
  const progressBar = useRef<HTMLDivElement | null>(null);
  const [selectedFilters, setSelectedFilters] = useState<Record<string, string>>({});
  const [filteredSlides, setFilteredSlides] = useState<ProductSlide[]>(slides);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [direction, setDirection] = useState(0);
  const [showingSlides, setShowingSlides] = useState<ProductSlide[]>([]);
  const isXl = useMediaQuery('(min-width: 1280px)');
  const isMd = useMediaQuery('(min-width: 768px)');

  const amountOfSlidesToShow = useMemo(() => {
    if (isXl) return 3;
    if (isMd) return 2;
    return 1;
  }, [isXl, isMd]);

  const [fetchedSelectedVehicle, setFetchedSelectedVehicle] = useState<boolean>(false);

  const [allMakes, setAllMakes] = useState<Make[] | []>([]);
  const [selectedMake, setSelectedMake] = useState<string>('');

  const [allModels, setAllModels] = useState<Model[] | []>([]);
  const [selectedModel, setSelectedModel] = useState<string>('');

  const [allSeries, setAllSeries] = useState<Series[] | []>([]);
  const [selectedSeries, setSelectedSeries] = useState<string>('');

  useLayoutEffect(() => {
    const vehicle = document.querySelector('[data-react="vehicle"]') as HTMLElement;
    if (!fitMy4x4 || !vehicle) return;
    const selected4x4 = vehicle.dataset?.props ? JSON.parse(vehicle.dataset.props) : {};
    setSelectedMake(selected4x4.make);
    setSelectedModel(selected4x4.model);
    setSelectedSeries(selected4x4.series);
    getMakes(setAllMakes);
    setFetchedSelectedVehicle(true);
  }, []);

  useEffect(() => {
    getModels(selectedMake, setAllModels);
  }, [selectedMake]);

  useEffect(() => {
    getSeries(selectedMake, selectedModel, setAllSeries);
  }, [selectedMake, selectedModel]);

  useEffect(() => {
    let newSlides = slides;

    if (selectedMake && fitMy4x4) {
      newSlides = newSlides.filter((slide) =>
        !slide.vehicles.length
          ? slide // no vehicles, so return the slide
          : slide.vehicles.some(
              // check if any of the vehicles match the selected vehicle
              (vehicle) =>
                vehicle.make == selectedMake &&
                (vehicle.model == selectedModel || !selectedModel) &&
                (vehicle.series == selectedSeries || !selectedSeries),
            ),
      );

      setFilteredSlides(newSlides);
      setCurrentIndex(0);
      setShowingSlides(newSlides.slice(0, amountOfSlidesToShow));
    }

    newSlides = newSlides.filter((slide) => {
      return Object.values(selectedFilters).every((filterID) => {
        return slide.bundleFiltersValues.includes(parseInt(filterID));
      });
    });

    setFilteredSlides(newSlides);
    setCurrentIndex(0);
    setShowingSlides(newSlides.slice(0, amountOfSlidesToShow));
  }, [selectedMake, selectedModel, selectedSeries, selectedFilters, amountOfSlidesToShow, slides]);

  useEffect(() => {
    if (!fetchedSelectedVehicle || !fitMy4x4) return;

    axios
      .post('/api/fitmy4x4/vehicle', {
        makeID: selectedMake,
        modelID: selectedModel,
        seriesID: selectedSeries,
      })
      .catch((error) => console.error(error));
  }, [selectedMake, selectedModel, selectedSeries]);

  useEffect(() => {
    if (showLoader) {
      axios
        .post('/api/bundles')
        .then((response) => {
          setSlides(response?.data);
          setShowLoader(false);
        })
        .catch((error) => console.error(error));
    }
  }, [showLoader]);

  const sliderProgressWidth = useMemo(() => {
    if (progressBar.current && filteredSlides.length >= amountOfSlidesToShow) {
      const boundingRect = progressBar.current?.getBoundingClientRect();
      const { width } = boundingRect;
      return width / filteredSlides.length;
    }
    return '100%';
  }, [filteredSlides, progressBar.current]);

  const sliderCurrentPosition = useMemo(() => {
    if (progressBar.current && filteredSlides.length >= amountOfSlidesToShow) {
      const boundingRect = progressBar.current?.getBoundingClientRect();
      const { width } = boundingRect;
      return (width / filteredSlides.length) * currentIndex;
    }
    return 0;
  }, [filteredSlides, progressBar.current, currentIndex]);

  useEffect(() => {
    if (showingSlides.length === 0) {
      setShowingSlides(filteredSlides.slice(0, amountOfSlidesToShow));
    }
    setShowingSlides((currentSlides) => {
      let newSlides = [...currentSlides];
      if (direction > 0) {
        newSlides.splice(0, 1);
        newSlides.push(filteredSlides[(currentIndex + (amountOfSlidesToShow - 1)) % filteredSlides.length]);
        return newSlides;
      } else if (direction < 0) {
        newSlides.pop();
        newSlides.unshift(filteredSlides[currentIndex % filteredSlides.length]);
        return newSlides;
      }
      return newSlides;
    });
  }, [currentIndex, filteredSlides]);

  const goToNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % filteredSlides.length);
  };

  const goToPrev = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + filteredSlides.length) % filteredSlides.length);
  };

  return (
    <div className={cls('relative mt-10')}>
      <div className="text-white mb-5 flex gap-4">
        <span>View components</span>
        <Switch.Root
          onCheckedChange={setShowComponents}
          className="relative h-[25px] w-[42px] cursor-default rounded-full bg-slate-600 shadow-[0_2px_10px] shadow-black outline-none focus:shadow-[0_0_0_2px] focus:shadow-black data-[state=checked]:bg-green"
        >
          <Switch.Thumb className="shadow-blackA4 block h-[21px] w-[21px] translate-x-0.5 rounded-full bg-white shadow-[0_2px_2px] transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-[19px]" />
        </Switch.Root>
      </div>
      <div className="grid grid-cols-1 gap-6 lg:grid-cols-4">
        <div className="flex w-full flex-col gap-4">
          <h3 className="text-white text-xl font-bold uppercase">Filters:</h3>
          <div className="grid w-full grid-cols-1 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:flex lg:flex-col">
            {filters.map((filter, index) => (
              <SelectField
                key={index}
                title={filter.title}
                value={selectedFilters[filter.filterID] || ''}
                onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                  setSelectedFilters((currentFilters) => {
                    let newFilters = { ...currentFilters };
                    newFilters[filter.filterID] = e.target.value;
                    return newFilters;
                  })
                }
                options={filter.values}
                clear={() =>
                  setSelectedFilters((currentFilters) => {
                    let newFilters = { ...currentFilters };
                    delete newFilters[filter.filterID];
                    return newFilters;
                  })
                }
              />
            ))}
            {fitMy4x4 && (
              <>
                <h4 className="text-white col-span-full mt-8 text-xl font-bold uppercase">Fit my 4x4:</h4>
                <SelectField
                  title="Make"
                  name="make"
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedMake(e.target.value)}
                  value={selectedMake}
                  clear={() => {
                    setSelectedMake('');
                    setSelectedModel('');
                    setSelectedSeries('');
                  }}
                  options={allMakes.map((make) => ({
                    value: make.ID,
                    label: make.Name,
                  }))}
                />
                <SelectField
                  title="Model"
                  name="model"
                  value={selectedModel}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedModel(e.target.value)}
                  clear={() => {
                    setSelectedModel('');
                    setSelectedSeries('');
                  }}
                  options={allModels.map((model) => ({
                    value: model.ID,
                    label: model.Name,
                  }))}
                />
                <SelectField
                  title="Series"
                  name="series"
                  value={selectedSeries}
                  clear={() => setSelectedSeries('')}
                  onChange={(e: ChangeEvent<HTMLSelectElement>) => setSelectedSeries(e.target.value)}
                  options={allSeries.map((series) => ({
                    value: series.ID,
                    label: series.Name,
                  }))}
                />
              </>
            )}
          </div>
        </div>

        <div className="relative flex w-full flex-col lg:col-span-3">
          <h4 className="text-white mb-4 flex items-center gap-2 text-xl font-normal">
            {filteredSlides.length} Product{filteredSlides.length != 1 && 's'} found
          </h4>
          <div className="flex h-full w-full">
            {showLoader && (
              <div className="flex w-full items-center justify-center">
                <Icon path={mdiLoading} spin={1} size={2} className="text-white" />
              </div>
            )}
            {filteredSlides.length > 0 ? (
              <AnimatePresence initial={false} mode="popLayout">
                {
                  showingSlides.map((item) => (
                    <Slide
                      slide={item}
                      layout
                      placeholderImage={placeholderImage}
                      showComponents={showComponents}
                      key={item?.code}
                      custom={1}
                      initial={{ scale: 0.8, opacity: 0 }}
                      transition={{
                        type: 'spring',
                        stiffness: 200,
                        damping: 20,
                        mass: 1.0,
                      }}
                      animate={{ scale: 1, opacity: 1 }}
                      exit={{ scale: 0.8, opacity: 0 }}
                    />
                  )) as React.ComponentProps<typeof Slider>['children']
                }
              </AnimatePresence>
            ) : (
              <div className="text-white flex w-full items-center justify-center">No products found</div>
            )}
          </div>
          <div className="mt-6 flex items-center justify-between">
            <div className="relative w-1/2">
              <motion.div
                transition={{
                  type: 'spring',
                  stiffness: 200,
                  damping: 20,
                  mass: 1.0,
                }}
                animate={{ width: sliderProgressWidth, x: sliderCurrentPosition }}
                className="z-20 -mb-px border-b-2 border-green"
              />
              <div ref={progressBar} className="z-10 w-full border-b-2 border-white opacity-10" />
            </div>
            <div className="flex gap-2">
              <button className="z-30 bg-green p-5 disabled:opacity-50" onClick={goToPrev} disabled={filteredSlides.length <= amountOfSlidesToShow}>
                <ArrowRight />
              </button>
              <button className="z-30 bg-green p-5 disabled:opacity-50" onClick={goToNext} disabled={filteredSlides.length <= amountOfSlidesToShow}>
                <ArrowLeft />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { getPriceCodes, GetPriceTypesResponse } from '../../services/dealerPrices';
import { Option, SelectField } from '../FormikFields/SelectField';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../Tooltip';

const formikProps = () => {
  return {
    initialValues: {
      priceToggle: null,
    },

    onSubmit: (data: { priceToggle: string | null }) => {
      window.location.href = '/dealers/setPriceType?priceCode=' + (data.priceToggle ? encodeURIComponent(data.priceToggle) : '');
    },
  };
};

export const SelectPrice = () => {
  const [priceTypes, setPriceTypes] = useState<Option[]>([]);
  const [priceType, setPriceType] = useState<string>();
  const [showWarning, setShowWarning] = useState<boolean | null>(null);

  useEffect(() => {
    getPriceCodes().request.then((value: GetPriceTypesResponse) => {
      setPriceTypes(value.allPriceTypes);
      setPriceType(value.currentPriceType);
      setShowWarning(!value.isDefaultPriceType);
    });
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPriceType(e.target.value);
    formikProps().onSubmit({ priceToggle: e.target.value });
  };

  return (
    <Formik initialValues={formikProps().initialValues} onSubmit={formikProps().onSubmit}>
      <div className="flex items-center gap-4">
        {showWarning && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <ExclamationTriangleIcon className="mt-3 h-10 cursor-pointer text-amber-350" />
              </TooltipTrigger>
              <TooltipContent>Warning: You are not using the companies default price code.</TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}

        <span>Display Price:</span>

        <div className="w-48">
          <SelectField invertColor={true} title="Select Price" value={priceType} name="priceToggle" onChange={onChange} options={priceTypes} />
        </div>
      </div>
    </Formik>
  );
};

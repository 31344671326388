import cls from 'classnames';
import { motion } from 'framer-motion';
import { forwardRef } from 'react';

interface Product {
  code: string;
  description: string;
  image: string;
  name: string;
  price: string;
  salePrice: string;
}

interface Part extends Product {
  quantity: number;
  links: {
    page: string;
  };
}

export interface ProductSlide extends Product {
  parts: Part[];
  links: {
    page: string;
    addToQuote: string;
    removeFromQuote: string;
  };
  vehicles: [
    {
      make: string;
      model: string;
      series: string;
    },
  ];
  isInQuote: boolean;
  bundleFiltersValues: number[];
}

export const Slide = motion(
  forwardRef<
    HTMLDivElement,
    {
      slide: ProductSlide;
      placeholderImage?: string;
      showComponents: boolean;
    }
  >(({ slide, placeholderImage, showComponents, ...props }, ref) => (
    <div ref={ref} className="flex flex-shrink-0 basis-full flex-col items-start px-3 md:basis-1/2 xl:basis-1/3" {...props}>
      <img
        src={slide.image || placeholderImage || '/assets/frontend/placeholder_4x4.png'}
        alt={slide.name}
        className={cls('aspect-video w-full', slide.image || placeholderImage ? 'object-cover' : 'object-contain')}
      />
      <div className="my-4 flex h-44 flex-col gap-4">
        <h4 className="text-white line-clamp-3 text-xl font-bold uppercase">{slide.name}</h4>
        <p className="line-clamp-3 w-[95%] text-base text-slate-325">{slide.description}</p>
      </div>
      <a href={slide.links.page} className="group:transition-all group text-white mb-4 flex items-center gap-2 text-xl font-normal">
        <span className="transition-all group-hover:text-slate-325">More info</span>
        <svg
          className="h-4 w-4 fill-white transition-all group-hover:fill-slate-325"
          viewBox="0 0 20 20"
          fill="current"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.666484 11.1667L0.666484 8.83333L14.6665 8.83332L8.24982 2.41666L9.90648 0.759991L19.1465 9.99999L9.90648 19.24L8.24982 17.5833L14.6665 11.1667L0.666484 11.1667Z"
            fill="current"
          />
        </svg>
      </a>
      {showComponents ? (
        <div className="w-full">
          <div className="text-md text-white font-bold uppercase">Contains</div>
          <hr className="mt-3 w-full" />
          <div className="w-full">
            {slide.parts.map((part, index) => (
              <div key={index} className="grid grid-cols-3 gap-8 border-b border-slate-325/10 py-4 text-sm">
                <div className="col-span-2 flex flex-col">
                  <div className="text-white font-medium">{part.name}</div>
                  <div className="text-xs font-medium text-slate-325">{part.code}</div>
                </div>
                <div className="text-white">{part.quantity}</div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="flex w-full items-center gap-10">
          <div className="mt-2 flex flex-col">
            <p className="text-xxs font-light uppercase leading-none tracking-thickest text-slate-350">Price</p>
            <p className="text-white text-2xl font-normal">{slide.salePrice ? 'Now ' + slide.salePrice : slide.price}</p>
          </div>
          <a
            href={slide.isInQuote ? slide.links.removeFromQuote : slide.links.addToQuote}
            className="ml-auto inline-flex w-auto place-self-end bg-green px-4 py-4 text-sm font-extrabold uppercase text-black transition-all hover:opacity-80 disabled:opacity-60"
          >
            {slide.isInQuote ? 'Remove from quote' : 'Add to quote'}
          </a>
        </div>
      )}
    </div>
  )),
);

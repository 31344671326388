import { CogIcon } from '@heroicons/react/20/solid';
import { Fragment, useEffect, useRef, useState } from 'react';
import { TextAreaField } from './FormFields/TextAreaField';
import { TextField } from './FormFields/TextField';

export const OrderItems = ({}) => {
  const [products, setProducts] = useState(window?.initialOrder?.orderItems ? window?.initialOrder?.orderItems : []);
  const [processingAction, setProcessingAction] = useState(false);
  const [reference, setReference] = useState(window?.initialOrder?.reference);
  const [comments, setComments] = useState(window?.initialOrder?.comments);

  const submitButton = useRef<HTMLButtonElement | null>(null);
  const form = useRef<HTMLFormElement | null>(null);
  const hasIssues = products.some((product) => product.available < product.quantity);

  useEffect(() => {
    submitButton.current = document.querySelector('#EditOrderForm_editOrderForm_action_submitOrderAction');
    form.current = document.querySelector('#EditOrderForm_editOrderForm');
  }, []);

  useEffect(() => {
    submitButton.current!.disabled = hasIssues;
  }, [hasIssues]);

  const addProducts = (e) => {
    setProducts((products) =>
      products.find((product) => product.code === e.detail.product.code)
        ? [...products.filter((product) => product.code !== e.detail.product.code), e.detail.product]
        : [...products, e.detail.product],
    );
  };

  const removeProduct = (e) => {
    setProducts((products) =>
      products.find((product) => product.code === e.detail.product.code)
        ? [...products.filter((product) => product.code !== e.detail.product.code)]
        : products,
    );
  };

  useEffect(() => {
    document.addEventListener('updateProducts', function (event) {
      //@ts-ignore
      setProducts(event.detail.products);
    });
    document.addEventListener('addProduct', (e) => addProducts(e));
    document.addEventListener('removeProduct', (e) => removeProduct(e));

    const submitInputs = document.querySelectorAll('input[type="submit"]');

    submitInputs.forEach((input) => {
      input.addEventListener('click', function () {
        // Add a new class to the element
        submitInputs.forEach((submitInput) => {
          submitInput.classList.add('opacity-50');
        });
        setProcessingAction(true);
      });
    });
  }, []);

  useEffect(() => {
    const productUpdateEvent = new CustomEvent('updateProducts', {
      detail: {
        products: products,
      },
      bubbles: true,
      cancelable: true,
      composed: false,
    });

    document.dispatchEvent(productUpdateEvent);
  }, [JSON.stringify(products)]);

  const editQuantity = (newQuantity, itemCode) => {
    const newProducts = products.map((product) => {
      if (product.code === itemCode) {
        return {
          ...product,
          quantity: newQuantity,
        };
      }
      return product;
    });
    setProducts(newProducts);
  };

  return (
    <div className="mt-10">
      <div>
        <h3 className="text-xl font-normal">Cart</h3>
        <TextField
          invertColor
          name="Reference"
          value={reference}
          onChange={(e) => setReference(e.target.value)}
          dealerTextField={true}
          placeholder="Reference"
          className="mb-8 flex w-full items-center space-x-4 bg-transparent font-light"
        />
        <TextAreaField
          invertColor
          name="Comments"
          dealerTextField={true}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
          placeholder="Additional Comments"
          className="flex w-full items-center space-x-4 bg-transparent font-light focus:outline-none"
        />
        <div className="mt-3 grid w-full grid-cols-4 gap-y-4">
          <div className="col-span-2 text-left text-xs font-light uppercase leading-none tracking-widest text-slate-450">Product Code</div>
          <div className="text-left text-xs font-light uppercase leading-none tracking-widest text-slate-450">Items</div>
          <div className="text-left text-xs font-light uppercase leading-none tracking-widest text-slate-450">Total Price</div>
          {products?.map((product, index) => {
            return (
              <Fragment key={index}>
                <div className="col-span-2">
                  <input type="hidden" name={`OrderItems[${index}][code]`} value={product.code} />
                  <span className="font-light">{product.code}</span>
                </div>
                <div>
                  <TextField
                    small
                    invertColor
                    min={1}
                    dealerTextField={true}
                    type="number"
                    name={`OrderItems[${index}][quantity]`}
                    value={product.quantity}
                    onChange={(e) => editQuantity(e.target.value, product.code)}
                    className="w-16 font-light"
                  />
                </div>
                <div className="text-slate-450">
                  {(product.price * product.quantity).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </div>

                {product.available < product.quantity ? (
                  <div className="col-span-4 -mt-3 text-red-500">
                    {product.available <= 0 && 'This item is out of stock'}
                    {product.available >= 1 && `There are not enough of this item in stock`}
                  </div>
                ) : null}
              </Fragment>
            );
          })}
        </div>
      </div>
      {hasIssues && (
        <div className="mt-4 border bg-slate-50 p-3 text-sm">
          You may save this order at any time. To submit this order for processing you must correct the issues highlighted above.
        </div>
      )}
      {processingAction && (
        <div className="-mb-6 mt-6 flex space-x-3">
          <CogIcon className="h-6 w-6 animate-spin" style={{ animationDuration: '3s' }} />
          <span>Processing</span>
        </div>
      )}
    </div>
  );
};

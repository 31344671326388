'use strict';
import { animated, useSpring } from '@react-spring/web';
import cls from 'classnames';
import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import PageWidthConstraint from './PageWidthConstraint';

interface Slide {
  title: string | undefined;
  caption: string;
  alt: string;
  src: string;
  button:
    | {
        title: string;
        url: string;
        anchor: string;
        openInNew: boolean;
      }
    | undefined;
}

interface SliderProps {
  slides: Slide[];
}

interface SlickArrow {
  onClick: MouseEventHandler<HTMLButtonElement> | undefined;
  currentSlide: number;
}

export const ArrowLeft = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.666484 11.1667L0.666484 8.83333L14.6665 8.83332L8.24982 2.41666L9.90648 0.759991L19.1465 9.99999L9.90648 19.24L8.24982 17.5833L14.6665 11.1667L0.666484 11.1667Z"
      fill="#18191B"
    />
  </svg>
);

export const ArrowRight = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.3335 8.83334V11.1667H5.33352L11.7502 17.5833L10.0935 19.24L0.853516 10L10.0935 0.76001L11.7502 2.41668L5.33352 8.83334H19.3335Z"
      fill="#18191B"
    />
  </svg>
);

export const ItemSlider = ({ slides }: SliderProps) => {
  const sliderRef = useRef<Slider | null>(null);
  const indexBar = useRef(null);
  const [positions, setPositions] = useState<number[]>([]);

  const NextArrow = ({ onClick, currentSlide }: SlickArrow) => (
    <button
      className="absolute bottom-10 right-5 z-30 hidden bg-green p-5 disabled:opacity-50 lg:block"
      disabled={currentSlide === slides.length - 1}
      onClick={onClick}
    >
      <ArrowLeft />
    </button>
  );

  const PrevArrow = ({ onClick, currentSlide }: SlickArrow) => (
    <button
      className="absolute bottom-10 right-20 z-30 hidden bg-green p-5 disabled:opacity-50 lg:block"
      disabled={currentSlide === 0}
      onClick={onClick}
    >
      <ArrowRight />
    </button>
  );

  const [springs, api] = useSpring(() => ({
    from: { x: 0 },
  }));

  const factorialOfStep = (step: number, amount: number) => {
    let stepArray = [];
    for (let i = 1; i <= amount; i++) {
      stepArray.push(step * i);
    }
    return stepArray;
  };

  useEffect(() => {
    //@ts-ignore
    const fullIndexBar = indexBar.current?.offsetWidth;
    const step = fullIndexBar / Math.ceil(slides.length / 4);
    setPositions([0, ...factorialOfStep(step, slides.length - 1)]);
  }, [indexBar.current]);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    autoplaySpeed: 5000,
    dotsClass: 'w-1/3',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: (dots: any) => {
      const index = dots.findIndex((dot: any) => dot.props.className === 'slick-active');
      api.start({
        from: {
          x: springs.x.animation.to,
        },
        to: {
          x: positions[index],
        },
      });
      return (
        <div>
          <PageWidthConstraint className="relative">
            <animated.div
              style={{
                //@ts-ignore
                width: indexBar.current?.offsetWidth / dots.length + 'px',
                ...springs,
              }}
              className="z-20 -mb-px border-b-2 border-green"
            />
            <div ref={indexBar} className="z-10 w-full border-b-2 border-black opacity-10" />
          </PageWidthConstraint>
        </div>
      );
    },
    //@ts-ignore
    nextArrow: <NextArrow />,
    //@ts-ignore
    prevArrow: <PrevArrow />,
    slidesToShow: 4,
    slidesToScroll: 4,
  };

  return (
    <div className="relative -mb-1.5">
      <Slider ref={(slider) => (sliderRef.current = slider)} {...settings} className="relative h-auto w-full px-3">
        {slides.map((item, index) => (
          <article key={index} className={cls('relative p-3', slides.length <= 4 && 'mb-10')}>
            <div className="mb-4 h-64">
              <img className="h-auto max-h-64 object-cover sm:w-full 2xl:w-full" src={item.src} alt={item.alt} />
            </div>
            <h4 className="py-5 text-2xl font-semibold uppercase">{item.title}</h4>
            <div className="content w-11/12" dangerouslySetInnerHTML={{ __html: item.caption }} />
            <a href={item.button?.url} className="align-center my-3 flex space-x-3 text-xl">
              <span>{item.button?.title}</span>{' '}
              <span className="mt-1">
                <ArrowLeft />
              </span>
            </a>
          </article>
        ))}
      </Slider>
    </div>
  );
};
